import React from 'react';
import { LocaleProvider } from 'antd2';
import enUS from 'antd2/es/locale-provider/en_US';
import { ConfigProvider } from 'antd';
import antd4_EN_US from 'antd/es/locale/en_US';
import antd4_ZH_CN from 'antd/es/locale/zh_CN';
import antd4_FR_FR from 'antd/es/locale/fr_FR';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { WsThemeProvider } from '@helloworld1812/app-global-components';
import { useTranslation } from 'react-i18next';

import { getSubAppContainer } from '@/utils/mfe';

export const queryClient = new QueryClient();

const localeByLanguage = {
  en: antd4_EN_US,
  zh: antd4_ZH_CN,
  fr: antd4_FR_FR,
} as const;

export function NonBusinessApp({ children }: { children: React.ReactNode }) {
  const { i18n } = useTranslation();

  const locale = localeByLanguage[i18n.resolvedLanguage as keyof typeof localeByLanguage] ?? antd4_EN_US;

  return (
    <WsThemeProvider>
      <ConfigProvider
        locale={locale}
        iconPrefixCls="ant4icon"
        {...(window.__POWERED_BY_QIANKUN__ && { getPopupContainer: () => getSubAppContainer() })}
      >
        <LocaleProvider locale={enUS}>
          <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
        </LocaleProvider>
      </ConfigProvider>
    </WsThemeProvider>
  );
}
