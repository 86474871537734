import dayjs from 'dayjs';
import moment from 'moment';
import { setHrAxiosLocale } from '@helloworld1812/hr-api';
import { WsLanguage, i18n, initI18n as initWsI18n } from '@helloworld1812/ws-i18n';
import { WsDeployEnv, isQaEnv } from '@helloworld1812/ws-env';

import 'dayjs/locale/fr';
import 'dayjs/locale/zh';
import 'moment/locale/fr';
import 'moment/locale/zh-cn';

import { queryClient } from './non-business-app';

const momentLocaleByLanguage: Record<WsLanguage, string> = {
  en: 'en',
  fr: 'fr',
  zh: 'zh-cn',
  es: 'es',
};

export const initI18n = () => {
  const deployEnv = process.env.DEPLOY_ENV as WsDeployEnv;
  const isInQaEnv = isQaEnv(deployEnv);

  initWsI18n({
    autoDetectLng: !isInQaEnv,
    // TODO: remove this once we have ai screening result modal and background check tested and saved the missing keys in prod env
    wsDeployEnv: isInQaEnv ? deployEnv : 'staging-2',
    locizeApiKey: isInQaEnv ? undefined : '50c6c853-4033-4061-b1f2-7d238e630fd0',
    supportedLngs: isInQaEnv ? ['en', 'fr'] : ['en', 'fr', 'zh'],
    cacheInLocalStorage: process.env.NODE_ENV !== 'development',
    onInitialized: () => {
      const language = (i18n.resolvedLanguage as WsLanguage) ?? 'en';
      dayjs.locale(language);
      setHrAxiosLocale(language);
      moment.locale(momentLocaleByLanguage[language] ?? 'en');
    },
    onLanguageChanged: (language) => {
      dayjs.locale(language);
      setHrAxiosLocale(language);
      moment.locale(momentLocaleByLanguage[language] ?? 'en');
      queryClient.invalidateQueries();
    },
    ns: ['hr-app', 'app-global-components'],
  });
};

export type Language = WsLanguage;
