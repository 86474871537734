import type { DvaInstance } from 'dva';

import { WS_DISABLE_WEBSOCKET } from '@/config';
import { registerModelIfNotExist } from '@/utils/dva';
import appModel from '@/models/app';
import webSocketModel from '@/models/webSocket';
import employeeOnboarding from '@/models/employee-onboarding';
import employeeProfile from '@/models/employee-onboarding/employee-profile';
import eVerify from '@/models/employee-onboarding/e-verify';

export function registerAllModels(app: DvaInstance) {
  registerModelIfNotExist(app, appModel);

  if (!WS_DISABLE_WEBSOCKET) {
    registerModelIfNotExist(app, webSocketModel);
  }

  registerModelIfNotExist(app, employeeOnboarding);
  registerModelIfNotExist(app, employeeProfile);

  registerModelIfNotExist(app, eVerify);
}

export type { RootState } from '@/types/app/models';

export type { DvaLoadingState } from 'dva-loading';
