import request from '../utils/request';
import { APIHOST } from '../config';

export async function fetchCompanyStaffs(payload, withPermittedLocations = false) {
  const path = withPermittedLocations ? '/v1/company_staffs/with_staff_permission_locations' : '/v1/company_staffs';
  const { page, perPage: per_page, query, status, sort_key: sortKey, order, employee_record_link_required } = payload;
  const data = {
    page,
    per_page,
  };
  if (query) {
    data.query = query;
  }
  if (status) {
    data.status = status;
  }
  if (sortKey && order) {
    const key = sortKey;
    let orderValue = order;
    // if (key === 'published' || key === 'url') key = 'status';
    orderValue = orderValue === 'ascend' ? 'asc' : 'desc';
    data.order = `${key},${orderValue}`;
  }

  if (employee_record_link_required) {
    data.needs_employee_record_mapping = employee_record_link_required;
  }

  return request('GET', APIHOST + path, data);
}
