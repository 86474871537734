import request from '../utils/request';
import { APIHOST } from '../config';

const userInfoPath = '/v1/users/me';

// Position
const positionsPath = '/v1/positions/mine';

// Applicant

export async function fetchUserInfo() {
  return request('GET', APIHOST + userInfoPath);
}

export async function fetchPositions(data) {
  const {
    ids = [],
    location,
    department,
    brand,
    type,
    page,
    per_page,
    status,
    query,
    sort_key: sortKey,
    order,
    show_simple_list: showSimpleList,
  } = data;

  const payload = {
    page,
    per_page,
  };

  if (type === 'department' && department !== 'all') {
    payload.department = department;
  }
  if (type === 'location' && location !== 'all') {
    payload.location = location;
  }
  if (type === 'location' && brand) payload.brand = brand;
  if (status !== 'all') {
    payload.status = status;
  }

  // sort setting
  if (sortKey && order) {
    let key = sortKey;
    let orderValue = order;
    if (key === 'published' || key === 'url') key = 'status';
    orderValue = orderValue === 'ascend' ? 'asc' : 'desc';
    payload.order = `${key},${orderValue}`;

    if (type === 'department' && sortKey === 'department') delete payload.order;
    if (type === 'location' && sortKey === 'location') delete payload.order;
  }

  if (query) {
    payload.query = query;
  }

  if (ids.length > 0) {
    payload.ids = ids;
  }

  if (showSimpleList) {
    payload.show_simple_list = showSimpleList;
  }

  return request('GET', APIHOST + positionsPath, payload);
}

export async function fetchAppSettings() {
  const path = '/v1/settings';
  return request('GET', APIHOST + path);
}

export async function getResetPwdToken() {
  const path = '/v1/users/reset_password_token';
  return request('GET', APIHOST + path);
}

export async function fetchCompanies() {
  const path = '/v1/users/companies';
  return request('GET', APIHOST + path);
}
