import { Dispatch, SetStateAction } from 'react';

import { ActionProps } from './components/Actions';

export type ProcessSettingsType = {
  id: string;
  name: string;
  status: 'ready' | 'not_ready';
  company_onboarding_modules: ModuleSettingType[];
};

export type ValidateStatus = 'success' | 'error';

export enum ModuleType {
  W4 = 'w4',
  FORMI9_COMPLIANCE_EVERIFY = 'formi9_compliance_everify',
  ADP = 'adp',
  COLLECT_INFO = 'collect_info',
  SIGNING = 'signing',
  WOTC = 'wotc',
  BASIC_INFORMATION = 'basic_information',
  I9 = 'i9',
  DIRECT_DEPOSIT = 'direct_deposit',
  PAYCHEX = 'paychex',
  US_STATE_TAX = 'us_state_tax',
  COMPANY_DOCUMENTS = 'company_documents',
  MICROSERVICE = 'microservice',
  UKG = 'ukg',
  CFA = 'cfa',
  ADP_RUN = 'ADP RUN',
  ADP_RUN_SELF_ONBOARDING = 'ADP RUN v2.0',
  PAYLOCITY = 'paylocity',
  EHX_WOTC = 'ehx_wotc',
}

export enum QuestionMode {
  REQUIRED = 'required',
  OPTIONAL = 'optional',
  OFF = 'off',
}

export type QuestionAnswerValueType = string | number | boolean;

export interface QuestionAnswerType {
  [x: string]: QuestionAnswerValueType | QuestionAnswerType[];
}

export interface QuestionOptionType {
  code: string | number;
  codeValue: string | number;
  description?: string;
  name?: string;
  shortName?: string;
  longName?: string;
  valueDescription?: string;
  category?: string;
  [x: string]: unknown;
}

export interface QuestionFieldType {
  title: string;
  category: string;
  key: string;
  optional?: boolean;
  is_required?: boolean;
  required?: boolean;
  question_type: string;
  sequence: number;
  mode?: QuestionMode;
  fields?: QuestionFieldType[];
  options?: QuestionOptionType[] | { listItems: QuestionOptionType[]; codeListTitle: string };
  code_lists?: Record<string, QuestionOptionType[]>;
  [x: string]: unknown;
}

export interface FormSubmissionQuestionType extends QuestionFieldType {
  max_items?: number;
  min_items?: number;
}

export interface QuestionWithAnswerType extends QuestionFieldType {
  answer: {
    answer: QuestionAnswerType | QuestionAnswerType[];
    id: number;
  };
}

export interface QuestionType {
  category: string;
  fields: QuestionFieldType[];
}

export interface ConfigurationFormType {
  questions: QuestionType[];
}

export interface ModuleSettingFormType {
  questions: QuestionFieldType[];
}

export interface ModuleSettingType {
  id: string | number;
  actions?: ModuleSettingActionType[];
  description?: string;
  name?: string;
  module_type?: ModuleType;
  sequence?: number;
  config?: Record<string, unknown>;
  hrSelectedFields?: QuestionFieldType[];
  employeeSelectedFields?: QuestionFieldType[];
  status?: string;
  [x: string]: unknown;
}

export interface ModuleConfigurationType {
  actions?: ModuleConfigurationActionType[];
  description: string;
  help_link: string;
  name: string;
  type: ModuleType;
  config?: Record<string, unknown>;
  hrSelectedFields?: QuestionFieldType[];
  employeeSelectedFields?: QuestionFieldType[];
  [x: string]: unknown;
}

export interface ModuleConfigurationActionType {
  name: string;
  sequence: number;
  type: ActionType;
  description: string;
  assignees?: AssigneeType[];
  form?: ConfigurationFormType;
  questions?: QuestionType[];
}

export interface ModuleSettingActionType {
  id?: number;
  description?: string;
  name: string;
  sequence: number;
  action_type: ActionType;
  assignees?: AssigneeType[];
  form?: ModuleSettingFormType;
}

export enum ActionType {
  EMPLOYEE_FILL_BASIC_FORM = 'employee_fill_basic_information_form',
  HR_FILL_BASIC_FORM = 'hr_fill_basic_information_form',
  EMPLOYEE_FILL_PAYCHEX_FORM = 'employee_fill_paychex_form',
  HR_FILL_PAYCHEX_FORM = 'hr_fill_paychex_form',
  REVIEW_AND_SEND_TO_PAYCHEX = 'review_and_send_to_paychex',
  EMPLOYEE_SIGN_COMPANY_DOCUMENTS = 'employee_sign_company_document',
  HR_SIGN_COMPANY_DOCUMENTS = 'hr_sign_company_document',
  E_VERIFY_SECTION_1 = 'formi9_section1',
  E_VERIFY_SECTION_2 = 'formi9_section2',
  COLLECT_INFO_EMPLOYEE_FORM = 'employee_fill_form',
  COLLECT_INFO_HR_FORM = 'review_form',
  EMPLOYEE_FILL_ADP_FORM = 'employee_fill_adp_form',
  HR_FILL_ADP_FORM = 'hr_fill_adp_form',
  REVIEW_AND_SEND_TO_ADP = 'review_and_send_to_adp',
  W4_FORM = 'w4_form',
  EMPLOYEE_FILL_I9_form = 'employee_fill_i9_form',
  HR_FILL_I9_FORM = 'hr_fill_i9_form',
  HR_SUBMIT_E_VERIFY_CASE = 'hr_submit_e_verify_case',
  EMPLOYEE_FILL_US_STATE_TAX_FORM = 'employee_fill_us_state_tax_form',
  DIRECT_DEPOSIT_FORM = 'direct_deposit_form',
  EMPLOYEE_FILL_WOTC_FORM = 'employee_fill_wotc_form',
  EHX_WOTC_EMPLOYEE_FORM = 'ehx_wotc_employee_form',
}

export type PendingUpdateModuleType = {
  id: string | number;
  config?: Record<string, unknown>;
  updatedActions?: ModuleSettingActionType[];
  addedActions?: ModuleSettingActionType[];
};

export type TaskItemType = {
  id: number | string;
  name?: string;
  description?: string;
  type: ModuleType;
  status?: string;
  readyStatus?: boolean;
  baseUrl?: string; // used for external connectors
  moduleType?: string; // used for external connectors
  microservice_metadata_id?: number; // used for external connectors
  microservice_configuration_uuid?: string;
  warning?: {
    [x: string]: unknown;
  };
};

export type TaskItemProps = TaskItemType & {
  isSelected?: boolean;
  allowMultiple?: boolean;
  isExtraAdded?: boolean;
  isDisabled?: boolean;
  showIntegrationSetupNotice?: boolean;
  updateSelectedTasks: UpdateSelectedTasksFunc;
  addTask: AddTaskFunc;
  removeTask: RemoveTaskFunc;
};

export type TaskItemWithConfigurationType = {
  id: string | number;
  name?: string;
  description?: string;
  type: ModuleType;
  status?: string;
  [x: string]: unknown;
};

export type ConnectorPageProps = ActionProps & {
  task: TaskItemType;
  inPageSettings?: boolean;
  company?: Company;
  moduleConfigurations: ModuleConfigurations;
  moduleSettings: ModuleSettings;
  isEditInCreateProcess: boolean;
  isEditSavedModule: boolean;
  extraAddedTasks: TaskItemWithConfigurationType[];
  updatePendingUpdatedModules: Dispatch<SetStateAction<PendingUpdatedModules>>;
  updateModuleSettings: Dispatch<SetStateAction<ModuleSettings>>;
  updateModuleConfigurations: Dispatch<SetStateAction<ModuleConfigurations>>;
  isFetchingOnboardingModuleDetailsByType: boolean;
  getOnboardingModuleDetailsByType: GetOnboardingModuleDetailsByTypeFunc;
  isFetchingModuleSettings: boolean;
  getOnboardingModuleSettings: GetOnboardingModuleSettingsFunc;
};

export interface AssigneeType {
  id: number;
  name: string;
  email: string;
}

export interface CompanyRoleType {
  name: string;
  id: number;
  company_id: number;
}

export interface Company {
  id: number;
  brands: Brand[];
}

export interface Brand {
  id: number;
  company_id: number;
  name: string;
  nickname: string;
  number: string;
  slug: string;
  status: string;
  industry: string;
  friendly_id: string;
}

export interface DvaLoadingType {
  loading?: { effects: { [x: string]: boolean } };
}

export type UpdateSelectedTasksFunc = (params: {
  selectedTask: TaskItemWithConfigurationType;
  isSelected: boolean;
}) => void;

export type AddTaskFunc = (params: {
  addedTask: TaskItemWithConfigurationType | TaskItemWithConfigurationType[];
  sequence?: number;
}) => void;
export type RemoveTaskFunc = (task: TaskItemWithConfigurationType) => void;

export type UpdateCurrentSelectedTask = (task: TaskItemWithConfigurationType) => void;

export interface StepViewProps {
  inProcessSettingsPage?: boolean;
  onSettingsDone?: () => void;
  selectedTasks: TaskItemWithConfigurationType[];
  extraAddedTasks: TaskItemWithConfigurationType[];
  currentExistedTasks: TaskItemWithConfigurationType[];
  moduleConfigurations: ModuleConfigurations;
  updateStep: (step: string) => void;
  updateCurrentSelectedTask: UpdateCurrentSelectedTask;
}

export interface SelectTasksStepProps extends StepViewProps {
  isExtraAdded: boolean;
  updateSelectedTasks: UpdateSelectedTasksFunc;
  addTask: AddTaskFunc;
  removeTask: RemoveTaskFunc;
  moduleConfigurations: ModuleConfigurations;
}

export interface ConfigureTasksStepProps extends StepViewProps {
  currentSelectedTask: TaskItemWithConfigurationType;
  isEditInCreateProcess: boolean;
  isEditSavedModule: boolean;
  updateNotReadyModulesIdInCreateProcess: Dispatch<SetStateAction<Array<string | number>>>;
  updatePendingUpdatedModules: Dispatch<SetStateAction<PendingUpdatedModules>>;
  updateModuleSettings: Dispatch<SetStateAction<ModuleSettings>>;
  isFetchingOnboardingModuleDetailsByType: boolean;
  moduleSettings: ModuleSettings;
  getOnboardingModuleDetailsByType: GetOnboardingModuleDetailsByTypeFunc;
  isFetchingModuleSettings: boolean;
  getOnboardingModuleSettings: GetOnboardingModuleSettingsFunc;
  updateModuleConfigurations: Dispatch<SetStateAction<ModuleConfigurations>>;
  updateIsEditInCreateProcess: (isEditInCreateProcess: boolean) => void;
}

export interface ReviewAndSaveStepProps extends StepViewProps {
  processSettingId: string;
  tasksInProcess: TaskItemWithConfigurationType[];
  name: string;
  extraAddedTasks: TaskItemWithConfigurationType[];
  currentExistedTasks: TaskItemWithConfigurationType[];
  notReadyModulesIdInCreateProcess: Array<string | number>;
  pendingUpdatedModules: PendingUpdatedModules;
  removeTask: RemoveTaskFunc;
  setSelectedTasks: Dispatch<SetStateAction<Array<TaskItemWithConfigurationType>>>;
  moduleSettings: ModuleSettings;
  updateExtraAdd: (val: boolean) => void;
  getOnboardingModuleDetailsByType: GetOnboardingModuleDetailsByTypeFunc;
  updateIsEditInCreateProcess: (isEditInCreateProcess: boolean) => void;
  updateIsEditSavedModule: (isEditSavedModule: boolean) => void;
  updateCurrentExistedTasks: (tasks: TaskItemWithConfigurationType[]) => void;
  onRefresh: () => void;
}

export interface PendingUpdatedModules {
  [key: string]: PendingUpdateModuleType;
}

export interface ModuleConfigurations {
  [key: string]: ModuleConfigurationType;
}

export interface ModuleSettings {
  [key: string]: ModuleSettingType;
}

export type GetOnboardingModuleDetailsByTypeFunc = (type: string) => Promise<void>;
export type GetOnboardingModuleSettingsFunc = (id: number) => Promise<void>;
export interface ConnectorTaskItemType {
  sequence: number;
  title: string;
  assignment_category: 'employee' | 'company_staff';
  dependencies: {
    [x: string]: boolean;
  };
}
export interface MicroServiceItemType {
  id: number;
  app_name: string;
  app_title: string;
  base_url: string;
  tasks: {
    [x: string]: ConnectorTaskItemType;
  };
}

export enum IntegrationStatus {
  READY = 'ready',
  INTEGRATION_NOT_FOUND = 'integration not found',
  STATUS_NOT_AVAILABLE = 'status not available',
  INTEGRATION_NOT_ACCESSIBLE = 'integration not accessible',
  NOT_READY = 'not ready',
  INVALID_COMPANY_ACCESSED = 'invalid company accessed',
}
