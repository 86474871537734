/* eslint-disable no-prototype-builtins,no-loop-func,no-lonely-if */
import type { IconType } from 'antd/es/notification';
import React, { ReactNode } from 'react';
import dayjs from 'dayjs';
import _, { identity } from 'lodash';
import { nanoid } from 'nanoid';
import { notification, notification as ant4Notification, Modal as AntdModal } from 'antd';
import jstz from 'jstimezonedetect';
import queryString from 'qs';
import Pluralize from 'pluralize';
import HelloSign from 'hellosign-embedded';
import { IconHappyFace } from '@helloworld1812/ws-icons';
import { FunctionModuleKey } from '@helloworld1812/ws-biz-hooks';
import { TFunction } from 'i18next';

import constants from '@/utils/constants';
import type * as permissionModelTypes from '@/types/app/models/permission';
import type * as appModelTypes from '@/types/app/models/app';
import {
  automationRules,
  previewHOST,
  stageTypesSlugNameMap,
  APIHOST,
  applicationActionNameCodeMap,
  HELLOSIGN_CLIENT_ID,
  SKIP_DOMAIN_VERIFICATION_HELLOSIGN,
  copyLinkHOST,
  ENABLE_MFE,
} from '@/config';
import countries from '@/assets/countries';
import { flattenAvailablePermissionsFn } from '@/helpers/permission';
import { JobBoard, SponsorDetails, PositionApplication } from '@/api';
import { AppState } from '@/types/app/models/app';

const { PAGE_TYPES, STAGE_TYPE } = constants;
interface ActionPopoverProps {
  applicants: PositionApplication[];
  appState: object;
  pageType: string;
}

export const createAction = (type) => (payload) => ({ type, payload });

export const capitializeString = (string) => {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const capitializeUnderLineString = (string = '') => {
  return capitializeString(string.split('_').join(' '));
};

export const humanReadableDateString = (text: string, prependText = '', prependTextSameDay = '', showToday = true) => {
  return createHumanReadableDateString(text, { prependText, prependTextSameDay, showToday });
};

export const createHumanReadableDateString = (
  text: string,
  options: { prependText?: string; prependTextSameDay?: string; showToday?: boolean; t?: TFunction } = {}
) => {
  const { prependText = '', prependTextSameDay = '', showToday = true, t } = options;
  let result = dayjs(text).format('MMM D, YYYY');
  const isSameYear = dayjs().isSame(text, 'year');
  const isSameDay = dayjs().isSame(text, 'day');
  if (isSameYear) {
    // E.g. {prependText}Jan 14
    result = prependText + dayjs(text).format('MMM D');
  }
  if (isSameDay) {
    result = `${prependTextSameDay}${showToday ? (t?.('Today ') ?? 'Today') : ''}${dayjs(text).format('h:mm A')}`;
  }

  return result;
};

export const completeDateString = (text) => {
  const fullTimeString = dayjs(text).format('MMM D, YYYY h:mm A');
  return fullTimeString;
};

export const offerHumanReadableDateString = (text: string, options: { t?: TFunction } = {}) => {
  const { t } = options ?? { t: identity };
  if (!text) return '-';
  const fullDate = dayjs(text).format(dayjs().isSame(text, 'year') ? 'MMM D' : 'MMM D, YYYY');
  const fullTime = dayjs(text).format('h:mm A');
  const isSameDay = dayjs().isSame(text, 'day');

  if (isSameDay) {
    return t('Today {{fullTime}}', { fullTime });
  }
  return t('{{fullDate}} at {{fullTime}}', { fullDate, fullTime });
};

export const formatDateString = (input: string, format = 'MMM D, YYYY') => {
  return dayjs(input).format(format);
};

export function BackgroundCheckReadableDateString(date: string, isShowTime = false) {
  if (!date) {
    return null;
  }
  const formatStr = isShowTime ? 'MMM D, YYYY h:mm:ss A' : 'MMM D, YYYY';
  return dayjs(date).format(formatStr);
}

export const isOfferTemplateValidForSetAutoMoveRule = (signing = {}) => {
  const { offers = [], used_merge_tags: usedMergeTags = [] } = signing;

  const offersLength = (offers && offers.length) || 0;
  if (offersLength === 0) return false;

  // if offer has used salary merge tag, then salary_number and salary_unit must both
  // has a value for auto sent offer automation
  const hasSalaryMergeTag = usedMergeTags.indexOf('salary') !== -1;
  const hasStartDateMergeTag = usedMergeTags.indexOf('start_date') !== -1;

  // since there only one docx or text type template, so just get the first one works
  const {
    salary_number: salaryNumber,
    salary_unit: salaryUnit,
    start_date_type: startDateType,
    start_date_value: startDateValue,
  } = signing;

  if (hasSalaryMergeTag) {
    if (!salaryNumber || !salaryUnit) return false;
  }

  if (hasStartDateMergeTag) {
    if (startDateType === 'days_later') {
      if (!startDateValue || startDateValue.length === 0) {
        return false;
      }
    }

    if (startDateType === 'specific_date' && (startDateValue === undefined || startDateValue === null)) {
      return false;
    }
  }

  return true;
};

export const getLocationOfPosition = (digestkey, appState) => {
  const locations = appState.permittedLocations || [];

  let positions = [];
  for (const item of locations) {
    positions = positions.concat(item.positions || []);
  }

  const position = positions.filter((item) => item.digest_key === digestkey)[0];
  if (position) {
    return position.location.name;
  }

  return '';
};

export const getCountryCodeOfPosition = (digestkey, appState) => {
  const brands = appState.permittedBrandsWithLocations || [];

  let positions = [];
  brands.forEach((brand) => {
    const { groupLocations = {} } = brand;
    const { locations = [] } = groupLocations || {};
    locations.forEach((location) => {
      const { positions: locationPositions = [] } = location;
      positions = [...positions, ...locationPositions];
    });
  });

  const position = positions.filter((item) => item.digest_key === digestkey)[0];
  if (position) {
    return position.location.country;
  }

  return '';
};

export const getNextStage = (stageId, stages) => {
  const sortedStages = _.sortBy(stages, ['sequence']);
  const index = _.findIndex(sortedStages, { id: stageId });

  const nextStage = sortedStages[index + 1];
  if (index === -1 || !nextStage) return undefined;

  return nextStage;
};

export const getNextStageId = (stageId, stages) => {
  const sortedStages = _.sortBy(stages, ['sequence']);
  const index = _.findIndex(sortedStages, { id: stageId });

  const nextStage = sortedStages[index + 1];
  if (index === -1 || !nextStage) return undefined;

  return nextStage.id;
};

export const parseFilenameFromOfferTemplateFileURL = (url) => {
  if (url) {
    const m = url.toString().match(/.*\/(.+?)\./);
    if (m && m.length > 1) {
      return `${m[1]}.docx`;
    }
  }
  return '';
};

export const getJsonFromUrl = (hashBased) => {
  let query;
  if (hashBased) {
    const pos = window.location.href.indexOf('?');
    if (pos === -1) return [];
    query = window.location.href.substr(pos + 1);
  } else {
    query = window.location.search.substr(1);
  }
  const result = {};
  query.split('&').forEach((partP) => {
    let part = partP;
    if (!part) return;
    part = part.split('+').join(' '); // replace every + with space, regexp-free version
    const eq = part.indexOf('=');
    let key = eq > -1 ? part.substr(0, eq) : part;
    const val = eq > -1 ? decodeURIComponent(part.substr(eq + 1)) : '';
    const from = key.indexOf('[');
    if (from === -1) {
      result[decodeURIComponent(key)] = val;
    } else {
      const to = key.indexOf(']', from);
      const index = decodeURIComponent(key.substring(from + 1, to));
      key = decodeURIComponent(key.substring(0, from));
      if (!result[key]) result[key] = [];
      if (!index) result[key].push(val);
      else result[key][index] = val;
    }
  });
  return result;
};

export const formatGoogleMapPlace = (googleMapPlace) => {
  const { address_components: addressComponents = [] } = googleMapPlace;

  const googleResult = {
    postalCode: undefined,
    country: undefined,
    state: undefined,
    city: undefined,
    // street number + route equals street_address
    route: undefined,
    // streetNumber: undefined,
  };

  addressComponents.forEach((item) => {
    const { types, short_name: shortName } = item;

    // postal code
    if (types.indexOf('postal_code') !== -1) {
      googleResult.postalCode = shortName;
    }

    // country
    if (types.indexOf('country') !== -1) {
      googleResult.country = shortName;
    }

    // state
    if (types.indexOf('administrative_area_level_1') !== -1) {
      googleResult.state = shortName;
    }

    // city
    if (types.indexOf('locality') !== -1) {
      googleResult.city = shortName;
    }

    // route
    if (types.indexOf('route') !== -1) {
      googleResult.route = shortName;
    }

    // stree_number
    if (types.indexOf('street_number') !== -1) {
      googleResult.streetNumber = shortName;
    }
  });

  // https://workstreamhq.atlassian.net/browse/WS-10610
  // If the city is not fulfilled from 'locality', we need to read it from 'sublocality'
  // This happens if you search '5645 Broadway, Bronx, NY 10463, USA'
  if (googleResult.city === undefined) {
    const subLocality = addressComponents.find((item) => item.types.includes('sublocality'));
    googleResult.city = subLocality?.short_name;
  }

  // https://workstreamhq.atlassian.net/browse/WS-5715
  // If the city is not fulfilled from 'locality', we need to read it from another key
  // This happens if you search '28116 The Old Rd, Valencia, CA, USA' in the
  // google auto complete input. It's rare of not having a city from the google
  // auto complete, but it happens.
  if (googleResult.city === undefined) {
    const neighborhood = addressComponents.find((item) => item.types.indexOf('neighborhood') !== -1);
    if (neighborhood) googleResult.city = neighborhood.short_name;
  }

  const formAddressResult = {
    country: googleResult.country,
    state: googleResult.state,
    city: googleResult.city,
    postal_code: googleResult.postalCode,
    // street_address: ,
  };

  const { route = '' } = googleResult;
  const { streetNumber } = googleResult;

  if (streetNumber && streetNumber.length > 0) {
    formAddressResult.street_address = `${googleResult.streetNumber} ${googleResult.route}`;
  } else {
    formAddressResult.street_address = route;
  }

  return formAddressResult;
};

export const assembleGoogleAddressFromformAddressResult = (formAddressResult) => {
  if (formAddressResult.is_remote) {
    return `Remote${formAddressResult.country ? `, ${formAddressResult.country}` : ''}`;
  }

  let googleAddress = '';

  if (formAddressResult.street_address) {
    googleAddress = googleAddress.concat(`${formAddressResult.street_address}`);
  }

  if (formAddressResult.city) {
    const comma = `${googleAddress.length > 0 ? ',' : ''}`;
    googleAddress = googleAddress.concat(`${comma} ${formAddressResult.city}`);
  }

  if (formAddressResult.state) {
    const comma = `${googleAddress.length > 0 ? ',' : ''}`;
    googleAddress = googleAddress.concat(`${comma} ${formAddressResult.state}`);
  }

  if (formAddressResult.country) {
    const comma = `${googleAddress.length > 0 ? ',' : ''}`;
    googleAddress = googleAddress.concat(`${comma} ${formAddressResult.country}`);
  }

  return googleAddress;
};

export const isAllowEditCompany = (appState) => {
  if (!_.isNull(appState.user.edit_user)) {
    return appState.user.company_admin.edit_company;
  } else {
    return false;
  }
};

export const isAllowUploadDocxInDraftTemplate = (appState) => {
  if (!_.isNull(appState.user.edit_user)) {
    return appState.user.company.is_compatible_with_old_offer;
  } else {
    return false;
  }
};

export const isAllowEditUsers = (appState) => {
  if (!_.isNull(appState.user.edit_user)) {
    return appState.user.company_admin.edit_user;
  } else {
    return false;
  }
};

export const isAllowEditEvaluationPlan = (appState) => {
  if (!_.isNull(appState.user.edit_evaluation)) {
    return appState.user.company_admin.edit_evaluation;
  } else {
    return false;
  }
};

export const isAllowEditSponsoredJobBoard = (appState) => {
  if (!_.isNull(appState.user.edit_sponsored_job)) {
    return appState.user.company_admin.edit_sponsored_job;
  } else {
    return false;
  }
};

export const shouldDisableEditPosition = (app, jobDetails) => {
  const company = app.company || {};
  if (!company.trial) return false;
  if (company.trial_remaining_days === 0) return true;
  if (company.available_positions_count > 0) return false;
  if (jobDetails.status === 'published') return false;
  return true;
};

export const isAllowCreateDepartment = (appState) => {
  if (!_.isNull(appState.user.company_admin)) {
    return appState.user.company_admin.edit_department;
  } else {
    return false;
  }
};

export const isAllowEditIntegrations = (appState) => {
  if (!_.isNull(appState.user.company_admin)) {
    return appState.user.company_admin.edit_integration;
  } else {
    return false;
  }
};

export const isAllowEditCustomFields = (appState) => {
  return !!appState?.user?.company_admin?.edit_custom_field;
};

export const isAllowEditAllCalendars = (appState) => {
  if (!_.isNull(appState.user.company_admin)) {
    return appState.user.company_admin.edit_all_calendars;
  } else {
    return false;
  }
};

export const isAllowEditEvaluations = (appState) => {
  if (!_.isNull(appState.user.company_admin)) {
    return appState.user.company_admin.edit_evaluation;
  } else {
    return false;
  }
};

export const isAllowDestroyAllFeedbacks = (appState) => {
  return appState.user.is_super_admin;
};

export const isAllowDestroyAllNotes = (appState) => {
  return appState.user.is_super_admin;
};

export const isAllowEditNotes = (appState) => {
  if (!_.isNull(appState.user.company_admin)) {
    return appState.user.company_admin.edit_note;
  } else {
    return false;
  }
};

export const isAllowEditMessages = (appState) => {
  if (!_.isNull(appState.user.company_admin)) {
    return appState.user.company_admin.edit_message;
  } else {
    return false;
  }
};

export const isAllowViewAssignedManagers = (appState) => {
  return appState.user.is_super_admin;
};

export const hasFunctionModule = (appState: AppState, moduleName: FunctionModuleKey) => {
  if (!_.isNull(appState.functionModules)) {
    return appState.functionModules.indexOf(moduleName) !== -1;
  } else {
    return false;
  }
};

export function isSupportAccount(appState: appModelTypes.AppState) {
  return appState.user.is_support_staff;
}

export function hasUserPermission(appState: appModelTypes.AppState, permissionName: string) {
  return appState.user.company_admin?.[permissionName] ?? false;
}

// This if for the 'granular_permissions' beta flag to check the nested permissions
export function hasUserPermissionV2(
  appState: appModelTypes.AppState,
  flattenAvailablePermissions: permissionModelTypes.SubState['flattenAvailablePermissions'],
  permissionName: string
) {
  if (appState.user.company_admin == null) {
    return false;
  }

  if (appState.user.company_admin.permissions.includes(permissionName)) {
    return true;
  }

  const foundPermission = _.find(flattenAvailablePermissions, { key: permissionName });

  // If found permission doesn't have child permissions, stop checking
  if (!foundPermission || !foundPermission.children || foundPermission.children.length === 0) {
    return false;
  }

  const childKeys = (foundPermission.children || [])
    .reduce(flattenAvailablePermissionsFn, [])
    .map((item: unknown) => item.key);

  if (_.intersection(childKeys, appState.user.company_admin.permissions).length > 0) {
    return true;
  }

  return false;
}

export const isAtLeastOneAutomationCreated = (stageSettings) => {
  return availableAutomationsForStage(stageSettings).length - notSettedAutomationForStage(stageSettings).length > 0;
};

export const notSettedAutomationForStage = (stageSettings) => {
  const availableAutomations = availableAutomationsForStage(stageSettings);
  const result = availableAutomations.filter((automationName) => {
    const automationDetail = automationRules[automationName];
    if (automationDetail.allowMultiple) return true;
    if (!isAutomationExistInStage(automationName, stageSettings)) return true;
    return false;
  });
  return result;
};

export const availableAutomationsForStage = (stageSettings) => {
  const { stage_type: stageType } = stageSettings;
  let automations = [];

  if (stageType === constants.STAGE_TYPE.BACKGROUND_CHECK) {
    automations = [
      constants.AUTOMATIONS.BACKGROUND_CHECK_ADVANCE,
      constants.AUTOMATIONS.BACKGROUND_CHECK_REMINDER,
      constants.AUTOMATIONS.BACKGROUND_CHECK_SEND,
    ];
  }

  // application form (including smart screen questions)
  if (stageType === constants.STAGE_TYPE.APPLICATION_FORM) {
    automations = [constants.AUTOMATIONS.APPLICATION_ADVANCE];
  }

  if (stageType === constants.STAGE_TYPE.ONLINE_FORM) {
    automations = [constants.AUTOMATIONS.FORM_ADVANCE, constants.AUTOMATIONS.FORM_REMINDER];
  }

  if (stageType === constants.STAGE_TYPE.ONLINE_QUIZ) {
    automations = [constants.AUTOMATIONS.QUIZ_ADVANCE, constants.AUTOMATIONS.QUIZ_REMINDER];
  }

  if (stageType === constants.STAGE_TYPE.OFFER) {
    if (stageSettings.signing) {
      automations = [
        constants.AUTOMATIONS.OFFER_SEND,
        constants.AUTOMATIONS.OFFER_ADVANCE,
        constants.AUTOMATIONS.OFFER_REMINDER,
      ];
    } else {
      automations = [];
    }
  }

  if (stageType === constants.STAGE_TYPE.SCHEDULING) {
    automations = [constants.AUTOMATIONS.MEETING_REMINDER, constants.AUTOMATIONS.SCHEDULING_REMINDER];
  }

  automations.push(constants.AUTOMATIONS.AUTO_REJECTION);

  return automations;
};

export const isAutomationExistInStage = (automationType, stageSettings = {}) => {
  // background check send
  if (automationType === constants.AUTOMATIONS.BACKGROUND_CHECK_SEND) {
    const autoSend = stageSettings.auto_send;
    return autoSend;
  }

  // background check advance
  if (automationType === constants.AUTOMATIONS.BACKGROUND_CHECK_ADVANCE) {
    const autoMove = stageSettings.auto_move;
    return autoMove;
  }

  if (automationType === constants.AUTOMATIONS.APPLICATION_ADVANCE) {
    const autoMove = stageSettings.auto_move;
    return autoMove;
  }

  if (automationType === constants.AUTOMATIONS.QUIZ_ADVANCE) {
    const { online_quiz: quiz = {} } = stageSettings;
    if (!quiz.quiz_questions || quiz.quiz_questions.length === 0) return false;
    const { passed_action: passedAction, failed_action: failedAction } = quiz;
    const isRuleExist = !_.isEmpty(passedAction) || !_.isEmpty(failedAction);
    return isRuleExist;
  }

  // offer send
  if (automationType === constants.AUTOMATIONS.OFFER_SEND) {
    const { auto_send: autoSend, signing } = stageSettings;
    if (!signing) return false;
    return autoSend;
  }

  // offer advance
  if (automationType === constants.AUTOMATIONS.OFFER_ADVANCE) {
    const { signing } = stageSettings;
    if (!signing) return false;
    return stageSettings.auto_move;
  }

  // form advance
  if (automationType === constants.AUTOMATIONS.FORM_ADVANCE) {
    const { form } = stageSettings;
    const { form_questions: questions = [] } = form;
    if (questions.length === 0) return false;
    return stageSettings.auto_move;
  }

  // form/quiz/offer/scheduling/meeting remind/auto rejection
  if (
    automationType === constants.AUTOMATIONS.FORM_REMINDER ||
    automationType === constants.AUTOMATIONS.QUIZ_REMINDER ||
    automationType === constants.AUTOMATIONS.OFFER_REMINDER ||
    automationType === constants.AUTOMATIONS.MEETING_REMINDER ||
    automationType === constants.AUTOMATIONS.SCHEDULING_REMINDER ||
    automationType === constants.AUTOMATIONS.BACKGROUND_CHECK_REMINDER ||
    automationType === constants.AUTOMATIONS.AUTO_REJECTION
  ) {
    let exist = false;
    const { stage_trigger_actions: actions = [] } = stageSettings;
    actions.forEach((action) => {
      if (action.action_type === automationRules[automationType].type) exist = true;
    });
    return exist;
  }

  return false;
};

export function toFormData(obj, form = null, namespace = null) {
  const fd = form || new window.FormData();
  let formKey;

  for (const property in obj) {
    // 当属性为空字符串时，也需要传参数进入
    if (obj.hasOwnProperty(property) && obj[property] !== undefined) {
      if (namespace) {
        formKey = `${namespace}[${property}]`;
      } else {
        formKey = property;
      }

      // if the property is an object, but not a File, use recursivity.
      if (obj[property] instanceof Date) {
        fd.append(formKey, obj[property].toISOString());
      } else if (obj[property] instanceof Array) {
        // fd.append(formKey, obj[property]);
        if (obj[property].length === 0) {
          fd.append(`${formKey}[]`, []);
          // fd.append(formKey, []);
        } else {
          obj[property].forEach((value) => {
            if (typeof value === 'object') {
              toFormData(value, fd, `${formKey}[]`);
            } else {
              fd.append(`${formKey}[]`, value);
            }
          });
        }
      } else if (typeof obj[property] === 'object' && !(obj[property] instanceof window.File)) {
        toFormData(obj[property], fd, formKey);
      } else {
        // if it's a string or a File object
        if (/^\s*$/.test(obj[property])) {
          fd.append(formKey, '');
        } else {
          fd.append(formKey, obj[property]);
        }
      }
    }
  }

  return fd;
}

/* eslint-disable */
export const objectToFormData = (obj, rootName, ignoreList) => {
  var formData = new FormData();

  function appendFormData(data, root) {
    if (!ignore(root)) {
      root = root || '';
      if (data instanceof File) {
        formData.append(root, data);
      } else if (Array.isArray(data)) {
        for (var i = 0; i < data.length; i++) {
          appendFormData(data[i], root + '[' + i + ']');
        }
      } else if (typeof data === 'object' && data) {
        for (var key in data) {
          if (data.hasOwnProperty(key)) {
            if (root === '') {
              appendFormData(data[key], key);
            } else {
              appendFormData(data[key], root + '.' + key);
            }
          }
        }
      } else {
        if (data !== null && typeof data !== 'undefined') {
          formData.append(root, data);
        }
      }
    }
  }

  function ignore(root) {
    return Array.isArray(ignoreList) && ignoreList.some((x) => x === root);
  }

  appendFormData(obj, rootName);

  return formData;
};

// get `id` type form question info from the app model
export const getCountryNamesForIdTypeQuestion = (appState) => {
  const {
    formQuestionTypeIdConfig: { identityRegexes },
  } = appState;
  const result = identityRegexes.map((country) => {
    const { country_name: countryName, country_code: countryCode } = country;
    return { countryName, countryCode };
  });
  return result;
};

export const getIdTypesForCountry = (countryCode, appState) => {
  const {
    formQuestionTypeIdConfig: { identityRegexes },
  } = appState;
  const country = _.find(identityRegexes, { country_code: countryCode });
  if (!country) return [];
  return country.identity_types.map((identity) => {
    const { value, label } = identity;
    return { value, label };
  });
};

export const getStateNameFromStateCode = (countryCode, stateCode, appState) => {
  const {
    formQuestionTypeIdConfig: { identityRegexes },
  } = appState;
  const country = _.find(identityRegexes, { country_code: countryCode });
  if (!country) return '';
  const identityItem = _.find(country.identity_types, { value: 'dl' });
  if (!identityItem) return '';
  const rule = _.find(identityItem.states, { state_code: stateCode });
  if (!rule || !rule.state_name) return '';

  return rule.state_name;
};

export const getStatesInfoOfCountry = (countryCode, appState) => {
  const {
    formQuestionTypeIdConfig: { identityRegexes },
  } = appState;
  const country = _.find(identityRegexes, { country_code: countryCode });
  if (!country) return {};

  // for now `states` property is under 'drive license(dl)' identity type
  const identityItem = _.find(country.identity_types, { value: 'dl' });

  if (!identityItem) return {};

  return identityItem.states.map((item) => {
    return {
      code: item.state_code,
      name: item.state_name,
    };
  });
};

export const getCountryNameByCode = (countryCode: 'US' | 'SG') => {
  const country = _.find(countries, { code: countryCode });
  const { name = '' } = country || {};
  return name;
};

export const createNewCalendarEvent = (event, calendarId) => {
  return {
    is_conflict: event.is_conflict,
    id: event.id,
    calendar_id: calendarId,
    start_time: new Date(event.start_time),
    end_time: new Date(event.end_time),
    pending: event.undecided_count,
    confirmed: event.confirmed_count,
    completed: event.completed_count,
    noshow: event.no_show_count,
    max_num_applicants: event.max_num_applicants,
    recurring_time_slot_id: event.recurring_time_slot_id,
    is_confirmation_needed: event.is_confirmation_needed,
    is_manually: event.is_manually,
    scheduling_stages: event.scheduling_stages,
    application_scheduling_stages: event.application_scheduling_stages,
    all_scheduling_stages: event.all_scheduling_stages,
    is_exclusive: event.is_exclusive,
    is_rescheduled: event.is_rescheduled,
    disabled: event.disabled || false,
    location_ids: event.location_ids,
    conflict_calendar: event.conflict_calendar,
  };
};

export const createNewGoogleCalendarEvent = (event) => {
  return {
    id: event.event_id,
    name: event.google_calendar_name,
    start_time: new Date(event.start_time),
    end_time: new Date(event.end_time),
    summary: event.summary,
    description: event.description,
    location: event.location,
    status: event.status,
    isExternal: true,
    isGoogle: true,
  };
};

export const createNewOutlookCalendarEvent = (event) => {
  return {
    id: event.event_id,
    name: event.outlook_calendar_name,
    start_time: new Date(event.start_time),
    end_time: new Date(event.end_time),
    summary: event.summary,
    description: event.description,
    location: event.location,
    isExternal: true,
    isOutlook: true,
  };
};

export const createTimeSlotRequestObject = (event) => {
  return {
    start_time: event.start_time.toISOString(),
    end_time: event.end_time.toISOString(),
    // Intl.DateTimeFormat().resolvedOptions() not supported in IE11...
    // time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    time_zone: jstz.determine().name(),
    max_num_applicants: event.max_num_applicants,
    is_confirmation_needed: event.is_confirmation_needed,
    // DEMO: changed this line of code from 'event.scheduling_stage.map' to now
    // since the .scheduling_stage data is populated after calling the fetch
    // event api which happens when user click an event in calendar.
    // Now we support  'select all events'. In the delete flow we don't need
    // .scheduling_stages data to exist
    scheduling_stage_ids: (event.scheduling_stages || []).map((stage) => stage.id),
    all_scheduling_stages: event.all_scheduling_stages,
    // Is exclusive is opposite in this case
    // We are asking HRs if timeslots should be shared across positions - Checked = not exlusive
    is_exclusive: event.is_exclusive,
    location_ids: event.location_ids,
  };
};

/**
 * @param {array} events
 * @param {array|string} stageId
 * @param {boolean} checked Whether the stages or locations are checked or unchecked
 * @param {number|[]} locationId Location id to add or remove
 */
export const updateEventsWithStageId = (events, stageId, checked, locationId) => {
  return events.map((event) => {
    const { scheduling_stages: stages = [] } = event;

    let locationIds = event.location_ids;
    // Checked or unchecked all positions, remove all locations
    if (Array.isArray(locationId) && locationId.length === 0) {
      locationIds = [];
    } else if (typeof locationId === 'number') {
      if (checked) {
        locationIds.push(locationId);
      } else if (locationIds.indexOf(locationId) !== -1) {
        locationIds.splice(locationIds.indexOf(locationId), 1);
      }
    }

    let newSchedulingStages;
    if (checked) {
      const stageIds = Array.isArray(stageId) ? stageId.map((id) => ({ id: Number(id) })) : [{ id: Number(stageId) }];
      // Combining new stage with old stages, de-duplicating
      newSchedulingStages = _.unionWith(stages, stageIds, (x, y) => x.id === y.id);
    } else {
      newSchedulingStages = Array.isArray(stageId)
        ? stages.filter((stage) => undefined === stageId.find((id) => Number(id) === stage.id))
        : stages.filter((stage) => stage.id !== Number(stageId));
    }

    return {
      ...event,
      scheduling_stages: newSchedulingStages,
      location_ids: locationIds,
    };
  });
};

export const converSlugToText = (slug) => {
  return slug?.split('_')?.join(' ');
};

export const formatBackgroundCheckSlug = (slug = '') => {
  const upperCaseterms = ['ssn'];
  upperCaseterms.forEach((term) => {
    slug = slug.replace(term, term.toUpperCase());
  });
  return slug
    .split('_')
    .filter(Boolean)
    .map((w) => w[0].toUpperCase() + w.slice(1))
    .join(' ');
};

export const isNumeric = (x) => {
  return !isNaN(x) && typeof x !== 'object' && x != Number.POSITIVE_INFINITY && x != Number.NEGATIVE_INFINITY;
};
export const isIntegerNumeric = (x) => {
  return (
    !isNaN(x) &&
    typeof x !== 'object' &&
    x != Number.POSITIVE_INFINITY &&
    x != Number.NEGATIVE_INFINITY &&
    parseInt(x) === +x
  );
};

export const getValidationSelectWidthForQuestionType = (type) => {
  const size = {
    categoryWidth: 100,
    actionWidth: 100,
  };

  switch (type) {
    case 'short_text':
    case 'long_text':
      size.categoryWidth = 135;
      size.actionWidth = 108;
      break;
    case 'date':
  }

  return size;
};

export const isEmail = (text) => {
  /* eslint-disable no-useless-escape */
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const isEmail = re.test(text);
  return isEmail;
};

export const sortObjectKeysByStringValue = (list) => {
  var sortable = [];
  for (var key in list) {
    sortable.push([key, list[key]]);
  }

  sortable.sort((a, b) => {
    const x = a[1].toLowerCase();
    const y = b[1].toLowerCase();
    return x < y ? -1 : x > y ? 1 : 0;
  });

  return sortable;
};

export const hasExistingApplicant = (slot) => {
  return (
    !_.isEmpty(slot.application_scheduling_stages) && _.find(slot.application_scheduling_stages, { is_in_stage: true })
  );
};

export const isPreviewableStage = (stageType) => {
  return [
    constants.STAGE_TYPE.APPLICATION_FORM,
    constants.STAGE_TYPE.ONLINE_FORM,
    constants.STAGE_TYPE.ONLINE_QUIZ,
  ].includes(stageType);
};

export const openApplicationFormPreviewPage = (nickname, digestKey) => {
  window.open(`${previewHOST}/positions/${nickname}/${digestKey}/apply?preview=true`);
};

export const openFormPreviewPage = (formId) => {
  window.open(`${previewHOST}/form/${formId}/preview`);
};

export const openQuizPreviewPage = (quizId) => {
  window.open(`${previewHOST}/online-quiz/${quizId}/preview`);
};

export const isSafariBrowser = () => {
  const isSafari = /^((?!chrome|android).)*safari/i.test(window.navigator.userAgent);
  return isSafari;
};

export const stripExtraNewLineFromHtmlString = (htmlString) => {
  const regex = /\n/g;
  return htmlString.replace(regex, '');
};

export const constructPositionsListPageURLOfLocation = (locationName = 'all') => {
  const positionsPageURL = `#/positions?location=${locationName}`;
  return positionsPageURL;
};

export const constructApplicantsPageURL = (digestKey, locationName, positionName) => {
  if (!digestKey) return '#/applicants/all-applicants';
  return `#/applicants/${encodeURIComponent(locationName?.replace(/%/g, ''))}/${encodeURIComponent(
    positionName?.replace(/%/g, '')
  )}/${digestKey}`;
};

export const progressNames = {
  JOB_SUMMARY: 'job summary',
  JOB_DESCRIPTION: 'job description',
  JOB_DETAILS: 'job details',
  JOB_POST: 'job post',
  PREVIEW_POST: 'preview job post',
  HIRING_PROCESS: 'hiring process',
  STAGES: 'stages',
  REJECTION_LETTERS: 'rejection templates',
  JOB_BOARDS: 'job boards',
  CONFIRMATION: 'confirmation',
  PUBLISH: 'publish',
  SHARE: 'share',
  REFERRAL: 'referral',
};

export const getStageTypeName = (stageType = 'unknown type') => {
  if (stageType === null) return 'unknown stage type';
  return stageTypesSlugNameMap[stageType] || stageType;
};

export const getStageTypeNameDisplay = (stageType = 'unknown type') => {
  return `${capitializeUnderLineString(getStageTypeName(stageType) || '')} stage`;
};

export const generatePreviewWindow = function () {
  if (this.previewWindow) this.previewWindow.close();
  this.previewWindow = window.open('', 'application_form_preview');
  this.previewWindow.document.write(
    `<div style="
      height: 100%;
      width: 100%;
      height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      font-family: sans-serif;
    ">
      <p style="font-size: 32px; margin-bottom: 0px;">⌛</p>
      <p style="color: #525252;">Generating preview. One moment...</p>
    </div>`
  );
};

export const updatePreviewWindow = function (data, isApply = false) {
  const cacheKey = data.position.new_cache_key;
  const { nickname } = this.props.app.company;
  const apply = isApply ? '/apply' : '';
  if (this.previewWindow) {
    const previewURL = `${previewHOST}/positions/${nickname}/${cacheKey}${apply}?preview=true`;
    this.previewWindow.location = previewURL;
  }
};

export const getParamFromUrl = () => {
  // TODO: remove hash routing after MFE phase 2 release
  let search = window.location.hash ? window.location.hash.split('?')[1] || '' : '';

  if (ENABLE_MFE) {
    search = window.location.search;
  }

  const params = queryString.parse(search, { ignoreQueryPrefix: true });
  return params;
};

let lastCleanUrl = undefined;
export const addParamToUrl = (pathname, obj) => {
  const params = getParamFromUrl();

  const queryObject = Object.assign(params, obj);
  const str = queryString.stringify(queryObject);
  const newUrl = ENABLE_MFE ? `${process.env.NX_MFE_SUB_APP_ROUTE_PREFIX_HR}${pathname}?${str}` : `#${pathname}?${str}`;

  window.history.replaceState('', '', newUrl);

  // Handle Segment page change, this place is specifc for URL change when calling the 'addParamToUrl' method, since
  // changing URL using this method is not detectable by the react-router history.listen method in 'model/app.js',
  // so we need to handle manually.

  // The fllowing part to clean up the data in applicants page, since the URL changing is quite messy there
  const queryObjectClean = _.cloneDeep(queryObject);
  delete queryObjectClean.digestkey;
  const cleanUrl = queryString.stringify(queryObjectClean);

  // sometime the addParmsToUrl will be called multiple time for the same page, we only count it as 1 page visit
  if (lastCleanUrl !== cleanUrl) {
    lastCleanUrl = cleanUrl;
    analytics.page({
      url: window.location.href,
      // TODO: remove hash routing after MFE phase 2 release
      path: ENABLE_MFE ? window.location.pathname : window.location.hash.split('?')[0],
    });
  }
};

export const removeParamFromUrl = (pathname, keys) => {
  // TODO: remove hash routing after MFE phase 2 release
  let search = window.location.hash ? window.location.hash.split('?')[1] || '' : '';

  if (ENABLE_MFE) {
    search = window.location.search;
  }

  const params = queryString.parse(search, { ignoreQueryPrefix: true });
  keys.forEach((key) => {
    delete params[key];
  });
  const str = queryString.stringify(params);
  const newUrl = ENABLE_MFE ? `${process.env.NX_MFE_SUB_APP_ROUTE_PREFIX_HR}${pathname}?${str}` : `#${pathname}?${str}`;
  window.history.replaceState('', '', newUrl);
};

/**
 * Group jobBoards by free/premium
 *
 * @param {array} jobBoards
 * @return {object} Job boards grouped by free/premium
 */
export const groupJobBoards = (jobBoards) => {
  const jobBoardsGroup = jobBoards.reduce((group, jobBoard) => {
    const type = jobBoard.is_sponsored ? 'premium' : 'free';
    if (!group[type]) group[type] = [];
    group[type].push(jobBoard);
    return group;
  }, {});

  return jobBoardsGroup;
};

export const getLowestPriceOfPaidBoard = (board) => {
  if (board && board.name === constants.JOB_BOARD.CRAIGSLIST_SPONSOR_NAME) return 10;
  if (board && board.name === constants.JOB_BOARD.CRAIGSLIST_GIGS_NAME) return 6;
  if (!(board && board.options && board.options.prices)) return 0;
  let lowestPrice;
  board.options.prices.forEach((item) => {
    const { amount } = item;
    if (lowestPrice === undefined) lowestPrice = amount;
    if (amount < lowestPrice) lowestPrice = amount;
  });
  return lowestPrice;
};

export const getJobBoardPrice = (board: JobBoard): number | undefined => {
  const isJoveo = board.name === constants.JOB_BOARD.JOVEO_NAME;
  const isZipRecruiter = board.name === constants.JOB_BOARD.ZIPRECRUITER_SPONSOR_NAME;
  const isCraigList = board.name === constants.JOB_BOARD.CRAIGSLIST_SPONSOR_NAME;
  const isCraigListGigs = board.name === constants.JOB_BOARD.CRAIGSLIST_GIGS_NAME;
  const isIndeed = board.name === constants.JOB_BOARD.INDEED_SPONSOR_NAME;
  const isTalroo = board.name === constants.JOB_BOARD.TALROO_SPONSOR_NAME;
  const sponsoredDetails = board.chosen && board.position_job_board && board.position_job_board.sponsored_details;

  if ((isJoveo || isZipRecruiter || isIndeed || isTalroo) && sponsoredDetails && sponsoredDetails.price) {
    return sponsoredDetails.price;
  } else if ((isCraigList || isCraigListGigs) && sponsoredDetails && sponsoredDetails.state && sponsoredDetails.city) {
    const selectedState = _.find(board.options?.prices?.[0]?.amount?.states, {
      state_code: board.position_job_board?.sponsored_details?.state,
    });
    if (!selectedState) return undefined;

    const selectedCity = _.find(selectedState.cities, {
      city_code: board.position_job_board?.sponsored_details?.city,
    });
    if (!selectedCity) return undefined;
    return selectedCity.price;
  } else if (isIndeed) {
    return 50;
  }
  return getLowestPriceOfPaidBoard(board);
};

export const getJobBoardTitle = (board) => {
  if (
    (board.name === constants.JOB_BOARD.CRAIGSLIST_SPONSOR_NAME ||
      board.name === constants.JOB_BOARD.CRAIGSLIST_GIGS_NAME ||
      board.name === constants.JOB_BOARD.JOVEO_NAME ||
      board.name === constants.JOB_BOARD.ZIPRECRUITER_SPONSOR_NAME) &&
    board.chosen &&
    board.position_job_board
  ) {
    return '';
  }
  return undefined;
};

// @deprecated use `board.position_job_board?.sponsored_details` with null value instead
// empty object should not be used anywhere
export const getJobBoardSponsoredDetail = (board: JobBoard): SponsorDetails | Partial<SponsorDetails> => {
  return board.position_job_board?.sponsored_details || {};
};

export const getDefaultSelectedTab = (stage, app, isAdd = false) => {
  // setup the selected stage menu item
  let selectedPanelMenu = constants.STAGE_SETTINGS.MENU_KEY_AUTOMATIONS;

  const { stage_type: stageType } = stage;

  if (isAdd && stageType === constants.STAGE_TYPE.APPLICATION_FORM) {
    selectedPanelMenu = constants.STAGE_SETTINGS.MENU_KEY_APPLICATION_FORM_QUESTIONS;
  }

  if (stageType === constants.STAGE_TYPE.ONLINE_QUIZ) {
    selectedPanelMenu = constants.STAGE_SETTINGS.MENU_KEY_QUIZ_QUESTIONS;
  }

  if (stageType === constants.STAGE_TYPE.ONLINE_FORM) {
    selectedPanelMenu = constants.STAGE_SETTINGS.MENU_KEY_FORM_QUESTIONS;
  }

  if (stageType === constants.STAGE_TYPE.OFFER) {
    selectedPanelMenu = constants.STAGE_SETTINGS.MENU_KEY_OFFER;
  }

  if (stageType === constants.STAGE_TYPE.SCHEDULING) {
    selectedPanelMenu = constants.STAGE_SETTINGS.MENU_KEY_AVAILABILITY;
  }

  if (stageType === constants.STAGE_TYPE.BACKGROUND_CHECK) {
    selectedPanelMenu = constants.STAGE_SETTINGS.MENU_KEY_BACKGROUND_CHECK_SETTINGS;
  }

  if (
    stageType === constants.STAGE_TYPE.REVIEW ||
    stageType === constants.STAGE_TYPE.LONG_TERM_HOLD ||
    stageType === constants.STAGE_TYPE.HIRING_COMPLETE
  ) {
    selectedPanelMenu = constants.STAGE_SETTINGS.MENU_KEY_HOLDING_STAGE;
  }

  if (stageType === constants.STAGE_TYPE.AI_SCREENING) {
    selectedPanelMenu = constants.STAGE_SETTINGS.MENU_KEY_AI_SCREENING_STAGE;
  }

  return selectedPanelMenu;
};

export const defaultQuestionOptions = ['', ''];
export const defaultQuestion = {
  id: undefined,
  title: '',
  question_type: 'short_text',
  is_required: true,
  options: defaultQuestionOptions,
};
export const buildObjectOptions = (valuesOrOptions: string[] | { label: string; value: string }[]) =>
  valuesOrOptions
    ? valuesOrOptions.map((option) => (typeof option === 'object' ? option : { label: option, value: option }))
    : [];
export const defaultFormElement = {
  id: undefined,
  title: 'Text block',
  question_type: 'text_heading',
  content: '',
  is_required: false,
};

export const getCandidateInfos = (candidateInfos, language = 'en') => {
  if (!candidateInfos || candidateInfos.length === 0) {
    // set default resume field for candidate info
    return language === 'en'
      ? [
          {
            ...defaultQuestion,
            title: 'Upload Resume',
            question_type: 'resume',
            is_required: false,
            field_enabled: true,
            sequence: 0,
            id: nanoid(),
            is_new: true,
          },
        ]
      : [
          {
            ...defaultQuestion,
            title: '上传简历',
            question_type: 'resume',
            is_required: false,
            field_enabled: true,
            sequence: 0,
            id: nanoid(),
            is_new: true,
          },
        ];
  } else {
    return candidateInfos.map((candidateInfo) => {
      if (candidateInfo.question_type === 'resume') {
        return language === 'en'
          ? { ...candidateInfo, title: 'Upload Resume' }
          : { ...candidateInfo, title: '上传简历' };
      }
      return candidateInfo;
    });
  }
};

export const getRequiredCandidateInfo = (language = 'en') => {
  return language === 'en'
    ? [
        {
          ...defaultQuestion,
          title: 'First name',
          question_type: 'short_text',
          is_required: true,
          key: nanoid(),
        },
        {
          ...defaultQuestion,
          title: 'Last name',
          question_type: 'short_text',
          is_required: true,
          key: nanoid(),
        },
        {
          ...defaultQuestion,
          title: 'Phone number (Mobile preferred)',
          question_type: 'telephone',
          is_required: true,
          key: nanoid(),
        },
        {
          ...defaultQuestion,
          title: 'Email address',
          question_type: 'email',
          is_required: true,
          key: nanoid(),
        },
      ]
    : [
        {
          ...defaultQuestion,
          title: '姓氏',
          question_type: 'short_text',
          is_required: true,
          key: nanoid(),
        },
        {
          ...defaultQuestion,
          title: '名字',
          question_type: 'short_text',
          is_required: true,
          key: nanoid(),
        },
        {
          ...defaultQuestion,
          title: '电话号码(推荐手机)',
          question_type: 'telephone',
          is_required: true,
          key: nanoid(),
        },
        {
          ...defaultQuestion,
          title: '电子邮箱',
          question_type: 'email',
          is_required: true,
          key: nanoid(),
        },
      ];
};

export const isInPasswordFlowOrLoginFlow = (pathname = '') => {
  return ['/login', '/reset-password/', '/forgot-password', '/select-company']
    .map((path) => pathname.startsWith(path))
    .includes(true);
};

export const isInSetPasswordFlow = (pathname = '') => {
  return pathname.startsWith('/set-password');
};

export const isInUserWizardFlow = (pathname = '') => {
  return pathname.startsWith('/user-onboarding');
};

export const isJobTargetCallback = (pathname = '') => {
  return pathname.startsWith('/job-target-callback');
};

export const prepareTagsPreview = function (applicant = {}, hiringProcess = {}, stage = {}, position = {}) {
  const { app } = this.props;
  return prepareTagsPreviewWithApp(app, applicant, hiringProcess, stage, position);
};

export const prepareTagsPreviewWithApp = (app, applicant = {}, hiringProcess = {}, stage = {}, position = {}) => {
  const result = {};

  if (app.company) {
    const {
      company: { name: companyName },
    } = app;
    result['company_name'] = companyName;
    result['hr_name'] = `${companyName} HR`;
  }

  let currentStage = stage;
  let currentPosition = position;
  let currentLocation = {};
  let currentBrand = {};

  if (!_.isEmpty(hiringProcess)) {
    const {
      department: { name: depName },
      location: { name: locationName, address },
      title,
    } = hiringProcess;
    result['department_name'] = depName;
    result['location_name'] = locationName;
    result['location_address'] = address;
    result['position_name'] = title;
  }

  if (!_.isEmpty(applicant)) {
    const {
      first_name,
      last_name,
      name,
      email,
      global_phone_number_for_sms,
      position_stage,
      position: applicantPosition,
      department: { name: depName } = { name: '' },
      location,
    } = applicant;
    currentStage = position_stage;
    currentPosition = applicantPosition;
    currentLocation = location;
    const stageName = position_stage?.name;

    result['applicant_email'] = email;
    result['applicant_first_name'] = first_name;
    result['applicant_last_name'] = last_name;
    result['applicant_full_name'] = name;
    result['applicant_phone_number'] = global_phone_number_for_sms;
    result['department_name'] = depName;
    result['hr_name'] = app.user.name;
    if (stageName) {
      result['stage_name'] = stageName;
    }
  }

  if (!_.isEmpty(currentStage)) {
    const { name: stageName, stage_type: stageType, signing } = currentStage;
    result['stage_name'] = stageName;
    if (stageType === 'offer' && signing) {
      const companySigner = _.find(signing?.signing_signers, { role: 'company' }) || {};
      result['company_signatory_email'] = companySigner.email;
      result['company_signatory_name'] = companySigner.name;
    }
  }

  if (!_.isEmpty(currentPosition)) {
    const {
      title,
      pay_amount,
      pay_frequency,
      rate_of_pay,
      department: { name: depName } = { name: '' },
      location: positionLocation,
    } = currentPosition;

    if (!_.isEmpty(positionLocation)) {
      currentLocation = positionLocation;
    }
    result['position_name'] = title;
    result['department_name'] = depName;
    result['pay_amount'] = pay_amount;
    result['pay_frequency'] = pay_frequency;
    result['rate_of_pay'] =
      !rate_of_pay && pay_amount && pay_frequency ? `${pay_amount} per ${pay_frequency}` : rate_of_pay;
  }

  if (!_.isEmpty(currentLocation)) {
    const { name: locationName, address } = currentLocation;

    currentBrand = currentLocation.brand;

    result['location_name'] = locationName;
    result['location_address'] = address;
  }

  if (!_.isEmpty(currentBrand)) {
    const { name: brandName } = currentBrand;

    result['brand_name'] = brandName;
  }

  return result;
};

export const getValidMergeTagsForInactiveApplicants = (mergeTags, stageTypes, previewTags = {}, isEmail, isSMS) => {
  let commonValidMergeTags = getCommonSMSAndEmailMergeTags(mergeTags, stageTypes, previewTags, isEmail, isSMS);
  const commonDeprecatedMergeTags = getCommonDeprecatedMergeTags(mergeTags, stageTypes);
  let validMergeTags = {
    ...commonValidMergeTags,
    ...commonDeprecatedMergeTags,
  };
  if (stageTypes.length === 1 && stageTypes[0] === 'base') {
    validMergeTags = _.omit(validMergeTags, ['stage_name']);
    commonValidMergeTags = _.omit(commonValidMergeTags, ['stage_name']);
  }

  return { validMergeTags, commonValidMergeTags, commonDeprecatedMergeTags };
};

export const getCommonValidMergeTags = (
  mergeTags,
  stageTypes,
  previewTags = {},
  blacklistTags: string[] = []
): { [label: string]: { label: string; preview: string } } => {
  let object = mergeTags[stageTypes[0]];
  let commonValidMergeTags = {};
  if (!object) {
    return commonValidMergeTags;
  }

  let commonKeys = Object.keys(object);
  _.each(stageTypes, (stageType) => {
    object = _.extend({}, object, mergeTags[stageType]);
    if (mergeTags[stageType]) {
      commonKeys = _.intersection(commonKeys, Object.keys(mergeTags[stageType]));
    }
  });
  commonValidMergeTags = _.pick(object, commonKeys);

  for (const [tag, content] of Object.entries(commonValidMergeTags)) {
    if (Object.keys(previewTags).indexOf(tag) !== -1 && previewTags[tag]) {
      commonValidMergeTags = _.extend({}, commonValidMergeTags, {
        [tag]: { ...content, ...{ preview: previewTags[tag] } },
      });
    }
  }

  return _.omit(commonValidMergeTags, blacklistTags);
};

export const getCommonSMSMergeTags = (mergeTags, stageTypes, previewTags = {}, blacklistTags: string[] = []) => {
  return getCommonValidMergeTags(mergeTags, stageTypes, previewTags, blacklistTags);
};

export const getCommonEmailMergeTags = (mergeTags, stageTypes, previewTags = {}, blacklistTags: string[] = []) => {
  return getCommonValidMergeTags(mergeTags, stageTypes, previewTags, blacklistTags);
};

export const getCommonSMSAndEmailMergeTags = (
  mergeTags,
  stageTypes,
  previewTags = {},
  isEmail,
  isSMS,
  sMSBlacklistTags: string[] = [],
  emailBlackListTags: string[] = []
) => {
  const smsTags = getCommonSMSMergeTags(mergeTags, stageTypes, (previewTags = {}), sMSBlacklistTags);
  const emailTags = getCommonEmailMergeTags(mergeTags, stageTypes, (previewTags = {}), emailBlackListTags);

  // this function is like an inner join
  if (isEmail && isSMS) return _.pick(smsTags, Object.keys(emailTags));
  if (isEmail && !isSMS) return emailTags;
  if (isSMS && !isEmail) return smsTags;

  return {};
};

export const getCommonDeprecatedMergeTags = (
  mergeTags,
  stageTypes
): { [label: string]: { label: string; preview: string } } => {
  const { deprecations: deprecatedMergeTags = {} } = mergeTags;
  const { base: baseDeprecatedMergeTags = {} } = deprecatedMergeTags;
  let object = deprecatedMergeTags[stageTypes[0]];
  let commonDeprecatedMergeTags = baseDeprecatedMergeTags;
  if (object) {
    let commonKeys = Object.keys(object);
    _.each(stageTypes, (stageType) => {
      object = _.extend({}, object, deprecatedMergeTags[stageType]);
      if (deprecatedMergeTags[stageType]) {
        commonKeys = _.intersection(commonKeys, Object.keys(deprecatedMergeTags[stageType]));
      }
    });
    commonDeprecatedMergeTags = _.pick(object, commonKeys);
  }
  return commonDeprecatedMergeTags;
};

export const validateMergeTags = (content, validMergeTags) => {
  const tags = content.match(/\{\{[\w\d_\[\]\|]+\}\}/g);
  let invalidTags = [];
  const validTags = Object.keys(validMergeTags);
  _.each(tags, (tag) => {
    if (validTags.indexOf(tag.replace(/[{}]/g, '')) === -1) {
      invalidTags.push(tag);
    }
  });
  return _.uniq(invalidTags);
};

export const validateRequiredMergeTags = (content, requiredMergeTags) => {
  const tags = content.match(/\{\{[\w\d_\[\]\|]+\}\}/g);
  let missingTags = [];
  _.each(requiredMergeTags, (tag) => {
    tag = '{{' + tag + '}}';
    if (!tags || tags.indexOf(tag) === -1) {
      missingTags.push(tag);
    }
  });
  return _.uniq(missingTags);
};

export const handleClickCopy = function ({ inputNode, urlCopied, inputFocused }) {
  const copyText = eval(inputNode);
  copyText.select();
  try {
    document.execCommand('copy');
    this.setState({ [urlCopied]: true }, () => {
      setTimeout(() => {
        copyText.blur();
        this.setState({ [urlCopied]: false, [inputFocused]: false });
      }, 2000);
    });
  } catch (err) {
    notification.error({ message: 'Copy failed, please copy manually.' });
  }
};

export const showNotification = (message: string) => {
  notification.open({
    message: message,
    icon: <IconHappyFace color="blue300" size="large" />,
  });
};

export const getRangeArrayFrom = (startChar, endChar) => {
  return String.fromCharCode(..._.range(startChar.charCodeAt(0), endChar.charCodeAt(0) + 1));
};

export const getFilterGroupFromGroups = (groups, firstLetter) => {
  if (groups.length <= 0) return '';

  if (firstLetter === '#') return firstLetter;

  const digitArray = Array.from('0123456789');
  if (digitArray.indexOf(firstLetter) >= 0) return '0-9';

  let group;
  for (let i = 0; i < groups.length; i++) {
    const groupName = groups[i];
    const groupArray = groupName.split('-');
    if (groupArray.length === 1) {
      if (groupName === firstLetter) {
        group = groupName;
        break;
      }
    } else {
      const [startChar, endChar] = groupArray;
      const charArray = getRangeArrayFrom(startChar, endChar);
      if (charArray.indexOf(firstLetter) >= 0) {
        group = groupName;
        break;
      }
    }
  }
  if (!group) group = groups[0];
  return group;
};

// argument

const {
  MOVE_TO_STAGE,
  SEND_EMAIL,
  SEND_SMS,
  ADD_NOTE,
  REJECT,
  SEND_SIGNING_REQUEST,
  UN_REJECT,
  SHARE_APPLICANT,
  MOVE_TO_POSITION,
  SEND_REMINDER,
  ORDER_BACKGROUND_CHECK,
  CONFIRM_MEETING,
  REQUEST_RESCHEDULE,
  CANCEL_MEETING,
  MARK_HIRED,
  MARK_NOT_HIRED,
  ASSIGN_TIME,
  PRINT_APPLICANT,
  MARK_SCHEDULE_COMPLETE,
  MARK_SCHEDULE_NO_SHOW,
  START_ONBOARDING,
  SEND_TO_PAYLOCITY,
  SEND_TO_UKG,
  MARK_AS_INACTIVE,
  SEND_EMAIL_INACTIVE,
  SEND_SMS_INACTIVE,
  REJECT_APPLICANT,
  UNREJECT_APPLICANT,
} = applicationActionNameCodeMap;

export const getAvailableActions = ({ applicants, appState, pageType }) => {
  let isBulkSelect = applicants.length > 1,
    acrossedStageTypes = [],
    acrossedStageStatuses = [],
    acrossedApplicantStatuses = [];

  // compute acrossed positions
  applicants.forEach((applicant) => {
    const { position_stage: stage, status: applicantStatus, stage_status: stageStatus } = applicant;
    if (acrossedStageTypes.indexOf(stage?.stage_type) === -1) acrossedStageTypes.push(stage?.stage_type);
    if (acrossedStageStatuses.indexOf(stageStatus) === -1) acrossedStageStatuses.push(stageStatus);
    if (acrossedApplicantStatuses.indexOf(applicantStatus) === -1) acrossedApplicantStatuses.push(applicantStatus);
  });

  const hasStagesModule = hasFunctionModule(appState, constants.FUNCTION_MODULES.HIRING_STAGES);
  // actions that will always be there
  let actions = [SHARE_APPLICANT, PRINT_APPLICANT];

  if (hasStagesModule && !_.isEqual(acrossedApplicantStatuses, ['inactive'])) {
    actions = [MOVE_TO_POSITION, ...actions];
  }

  // 'Start onboarding'
  const hasNoneOnboardingApplicants = applicants.filter((item) => item.employee === null).length > 0;
  if (
    _.isEqual(acrossedApplicantStatuses, ['hired']) &&
    hasNoneOnboardingApplicants &&
    hasFunctionModule(appState, constants.FUNCTION_MODULES.EMPLOYEE_ONBOARDING)
  ) {
    actions.push(START_ONBOARDING);
  }

  // 'Send SMS' & 'Send email'
  if (isAllowEditMessages(appState) && pageType != PAGE_TYPES.MESSAGES) {
    actions = [SEND_SMS, SEND_EMAIL, ...actions];
  }
  // 'Send SMS' & 'Send email' for Inactive Applicants
  if (isAllowEditMessages(appState) && pageType != PAGE_TYPES.MESSAGES && pageType === PAGE_TYPES.INACTIVE) {
    actions = [SEND_SMS_INACTIVE, SEND_EMAIL_INACTIVE, ...actions];
  }

  // 'Add note'
  if (isAllowEditNotes(appState)) {
    actions.push(ADD_NOTE);
  }

  // 'Move to stage'
  if (
    pageType !== PAGE_TYPES.MESSAGES &&
    hasFunctionModule(appState, constants.FUNCTION_MODULES.HIRING_STAGES) &&
    !_.isEqual(acrossedApplicantStatuses, ['inactive'])
  ) {
    actions.push(MOVE_TO_STAGE);
  }

  // 'Reject'
  if (
    !_.isEqual(acrossedApplicantStatuses, ['rejected']) &&
    !_.isEqual(acrossedApplicantStatuses, ['inactive']) &&
    pageType !== PAGE_TYPES.MESSAGES
  ) {
    actions.push(REJECT);
  }

  // 'Un-reject'
  if (acrossedApplicantStatuses.indexOf('rejected') !== -1) {
    actions.push(UN_REJECT);
    actions.push(UNREJECT_APPLICANT);
  }

  // 'Mark as hired'
  if (isBulkSelect) {
    if (pageType !== PAGE_TYPES.ALL_APPLICANTS && acrossedApplicantStatuses.indexOf('in_progress') === -1) {
      actions.push(MARK_HIRED);
    }
  } else {
    if (acrossedApplicantStatuses.indexOf('in_progress') !== -1) {
      actions.push(MARK_HIRED);
    }
  }

  // 'Mark as not hired'
  if (isBulkSelect) {
    if (pageType !== PAGE_TYPES.ALL_APPLICANTS && acrossedApplicantStatuses.indexOf('in_progress') === -1) {
      actions.push(MARK_NOT_HIRED);
    }
  } else {
    if (acrossedApplicantStatuses.indexOf('hired') !== -1) {
      actions.push(MARK_NOT_HIRED);
    }
  }

  if (
    hasFunctionModule(appState, constants.FUNCTION_MODULES.PAYLOCITY_INTEGRATION_WS_HIRING_TO_PAYLOCITY_ONBOARDING) &&
    !isBulkSelect &&
    acrossedApplicantStatuses.indexOf('hired') !== -1
  ) {
    actions.push(SEND_TO_PAYLOCITY);
  }

  if (
    hasFunctionModule(appState, constants.FUNCTION_MODULES.UKG_INTEGRATION_WS_HIRING_TO_UKG_ONBOARDING) &&
    !isBulkSelect &&
    acrossedApplicantStatuses.indexOf('hired') !== -1
  ) {
    actions.push(SEND_TO_UKG);
  }

  // 'Send reminder'
  if (
    _.intersection(acrossedStageTypes, [
      STAGE_TYPE.ONLINE_FORM,
      STAGE_TYPE.ONLINE_QUIZ,
      STAGE_TYPE.SCHEDULING,
      STAGE_TYPE.BACKGROUND_CHECK,
    ]).length > 0 &&
    hasStagesModule &&
    !_.isEqual(acrossedApplicantStatuses, ['inactive'])
  ) {
    actions.push(SEND_REMINDER);
  }
  if (
    acrossedStageTypes.indexOf(STAGE_TYPE.OFFER) !== -1 &&
    acrossedStageStatuses.indexOf('sent') !== -1 &&
    hasStagesModule
  ) {
    actions.push(SEND_REMINDER);
  }

  // Scheduling stage related actions
  // 'Mark complete'
  if (!_.isEqual(acrossedStageStatuses, ['Completed']) && !_.isEqual(acrossedApplicantStatuses, ['inactive'])) {
    actions.push(MARK_SCHEDULE_COMPLETE);
  }
  if (!_.isEqual(acrossedStageStatuses, ['No Show']) && !_.isEqual(acrossedApplicantStatuses, ['inactive'])) {
    // 'Mark as no-show'
    actions.push(MARK_SCHEDULE_NO_SHOW);
  }
  // 'Request reschedule'
  if (
    _.intersection(acrossedStageStatuses, ['Confirmed', 'Cancelled', 'Completed', 'No Show']).length > 0 &&
    !_.isEqual(acrossedApplicantStatuses, ['inactive'])
  ) {
    actions.push(REQUEST_RESCHEDULE);
  }

  // 'Cancel meeting'
  if (
    _.intersection(acrossedStageStatuses, ['Request Reschedule', 'Confirmed', 'Pending', 'Completed', 'No Show'])
      .length > 0 &&
    !_.isEqual(acrossedApplicantStatuses, ['inactive'])
  ) {
    actions.push(CANCEL_MEETING);
  }

  // 'Confirm meeting'
  if (
    _.intersection(acrossedStageStatuses, ['Pending']).length > 0 &&
    !_.isEqual(acrossedApplicantStatuses, ['inactive'])
  ) {
    actions.push(CONFIRM_MEETING);
  }

  // 'Assign time'
  if (
    !isBulkSelect &&
    _.intersection(acrossedStageStatuses, ['Not Submitted', 'Request Reschedule']).length > 0 &&
    !_.isEqual(acrossedApplicantStatuses, ['inactive'])
  ) {
    actions.push(ASSIGN_TIME);
  }

  // Signing stage related actions
  const showOnlineSigningRelatedActions = acrossedStageTypes.indexOf(STAGE_TYPE.OFFER) !== -1;

  if (showOnlineSigningRelatedActions) {
    // 'Send signing request'
    if (
      pageType === PAGE_TYPES.POSITION_APPLICANTS ||
      pageType === PAGE_TYPES.PROFILE ||
      (pageType === PAGE_TYPES.ALL_APPLICANTS && !isBulkSelect)
    ) {
      actions.push(SEND_SIGNING_REQUEST);
    }
  }

  // Background check stage related actions
  const showBackgroundCheckRelatedActions = acrossedStageTypes.indexOf(STAGE_TYPE.BACKGROUND_CHECK) !== -1;

  if (showBackgroundCheckRelatedActions) {
    // 'Send signing request'
    if (
      pageType === PAGE_TYPES.POSITION_APPLICANTS ||
      pageType === PAGE_TYPES.PROFILE ||
      (pageType === PAGE_TYPES.ALL_APPLICANTS && !isBulkSelect)
    ) {
      actions.push(ORDER_BACKGROUND_CHECK);
    }
  }

  // reorder the actions, check this doc: https://docs.google.com/document/d/1yXDtWgjOPX0NHO53RSHfhWxyMIklPyqAc06C5M6q25c/edit#heading=h.jj6igs3922ck
  const order = [
    MOVE_TO_STAGE,
    MOVE_TO_POSITION,
    SEND_EMAIL,
    SEND_SMS,
    SEND_REMINDER,
    CONFIRM_MEETING,
    REQUEST_RESCHEDULE,
    CANCEL_MEETING,
    ASSIGN_TIME,
    MARK_SCHEDULE_COMPLETE,
    MARK_SCHEDULE_NO_SHOW,
    ORDER_BACKGROUND_CHECK,
    SEND_SIGNING_REQUEST,
    ADD_NOTE,
    SHARE_APPLICANT,
    UN_REJECT,
    MARK_HIRED,
    MARK_NOT_HIRED,
    SEND_TO_PAYLOCITY,
    SEND_TO_UKG,
    PRINT_APPLICANT,
    REJECT,
    START_ONBOARDING,
  ];

  actions.sort((a, b) => order.indexOf(a) - order.indexOf(b));

  return actions;
};

const actionGroupBuilder = (actions) => {
  return (action) => {
    return actions.indexOf(action) !== -1;
  };
};

export const isInGroupMoveTo = actionGroupBuilder([MOVE_TO_STAGE, MOVE_TO_POSITION]);
export const isInGroupSend = actionGroupBuilder([SEND_EMAIL, SEND_SMS, SEND_REMINDER]);
export const isInGroupScheduling = actionGroupBuilder([
  CONFIRM_MEETING,
  REQUEST_RESCHEDULE,
  CANCEL_MEETING,
  ASSIGN_TIME,
  MARK_SCHEDULE_COMPLETE,
  MARK_SCHEDULE_NO_SHOW,
]);
export const isInMoveToGroupMarkAs = actionGroupBuilder([UN_REJECT, UNREJECT_APPLICANT, MARK_HIRED, MARK_NOT_HIRED]);

export const getNewApplicantTableActions = ({ applicants, appState, pageType }: ActionPopoverProps) => {
  let isBulkSelect = applicants.length > 1,
    acrossedStageTypes: string[] = [],
    acrossedStageStatuses: string[] = [],
    acrossedApplicantStatuses: string[] = [];

  // compute acrossed positions
  applicants.forEach((applicant) => {
    const { position_stage: stage, status: applicantStatus, stage_status: stageStatus } = applicant;

    if (stage) {
      if (acrossedStageTypes.indexOf(stage.stage_type) === -1) acrossedStageTypes.push(stage.stage_type);
    }
    if (acrossedStageStatuses.indexOf(stageStatus) === -1) acrossedStageStatuses.push(stageStatus);
    if (acrossedApplicantStatuses.indexOf(applicantStatus) === -1) acrossedApplicantStatuses.push(applicantStatus);
  });

  const hasStagesModule = hasFunctionModule(appState, constants.FUNCTION_MODULES.HIRING_STAGES);
  // actions that will always be there
  let actions = [SHARE_APPLICANT, PRINT_APPLICANT];

  if (hasStagesModule && pageType !== PAGE_TYPES.INACTIVE) {
    actions = [MOVE_TO_POSITION, ...actions];
  }

  // 'Start onboarding'
  const hasNoneOnboardingApplicants = applicants.filter((item) => item.employee === null).length > 0;
  if (
    _.isEqual(acrossedApplicantStatuses, ['hired']) &&
    hasNoneOnboardingApplicants &&
    hasFunctionModule(appState, constants.FUNCTION_MODULES.EMPLOYEE_ONBOARDING)
  ) {
    actions.push(START_ONBOARDING);
  }

  // 'Send SMS' & 'Send email'
  if (isAllowEditMessages(appState) && pageType != PAGE_TYPES.MESSAGES && pageType !== PAGE_TYPES.INACTIVE) {
    actions = [SEND_SMS, SEND_EMAIL, ...actions];
  }

  // 'Send SMS' & 'Send email' for Inactive Applicants
  if (isAllowEditMessages(appState) && pageType != PAGE_TYPES.MESSAGES && pageType === PAGE_TYPES.INACTIVE) {
    actions = [SEND_SMS_INACTIVE, SEND_EMAIL_INACTIVE, ...actions];
  }

  // 'Move to stage'
  if (
    pageType !== PAGE_TYPES.MESSAGES &&
    hasFunctionModule(appState, constants.FUNCTION_MODULES.HIRING_STAGES) &&
    pageType !== PAGE_TYPES.INACTIVE
  ) {
    actions.push(MOVE_TO_STAGE);
  }

  // 'Reject'
  if (
    !_.isEqual(acrossedApplicantStatuses, ['rejected']) &&
    pageType !== PAGE_TYPES.MESSAGES &&
    pageType !== PAGE_TYPES.INACTIVE
  ) {
    actions.push(REJECT);
    actions.push(REJECT_APPLICANT);
  }

  // 'Un-reject'
  if (acrossedApplicantStatuses.indexOf('rejected') !== -1) {
    actions.push(UN_REJECT);
    actions.push(UNREJECT_APPLICANT);
  }

  // 'Mark as hired'
  if (isBulkSelect) {
    if (pageType !== PAGE_TYPES.ALL_APPLICANTS && acrossedApplicantStatuses.indexOf('in_progress') !== -1) {
      actions.push(MARK_HIRED);
    }
  } else if (acrossedApplicantStatuses.indexOf('in_progress') !== -1) {
    actions.push(MARK_HIRED);
  }

  // 'Mark as not hired'
  if (isBulkSelect) {
    if (pageType !== PAGE_TYPES.ALL_APPLICANTS && acrossedApplicantStatuses.indexOf('hired') !== -1) {
      actions.push(MARK_NOT_HIRED);
    }
  } else if (acrossedApplicantStatuses.indexOf('hired') !== -1) {
    actions.push(MARK_NOT_HIRED);
  }

  if (
    hasFunctionModule(appState, constants.FUNCTION_MODULES.PAYLOCITY_INTEGRATION_WS_HIRING_TO_PAYLOCITY_ONBOARDING) &&
    !isBulkSelect &&
    acrossedApplicantStatuses.indexOf('hired') !== -1
  ) {
    actions.push(SEND_TO_PAYLOCITY);
  }

  if (
    hasFunctionModule(appState, constants.FUNCTION_MODULES.UKG_INTEGRATION_WS_HIRING_TO_UKG_ONBOARDING) &&
    !isBulkSelect &&
    acrossedApplicantStatuses.indexOf('hired') !== -1
  ) {
    actions.push(SEND_TO_UKG);
  }

  if (
    !_.isEqual(acrossedApplicantStatuses, ['rejected']) &&
    !_.isEqual(acrossedApplicantStatuses, ['hired']) &&
    pageType !== PAGE_TYPES.INACTIVE
  ) {
    actions.push(MARK_AS_INACTIVE);
  }

  // 'Send reminder'
  if (isBulkSelect) {
    if (
      hasStagesModule &&
      _.difference(acrossedStageTypes, [
        STAGE_TYPE.ONLINE_FORM,
        STAGE_TYPE.ONLINE_QUIZ,
        STAGE_TYPE.SCHEDULING,
        STAGE_TYPE.BACKGROUND_CHECK,
        STAGE_TYPE.OFFER,
      ]).length === 0
    ) {
      actions.push(SEND_REMINDER);
    }
  } else {
    if (
      _.intersection(acrossedStageTypes, [
        STAGE_TYPE.ONLINE_FORM,
        STAGE_TYPE.ONLINE_QUIZ,
        STAGE_TYPE.SCHEDULING,
        STAGE_TYPE.BACKGROUND_CHECK,
      ]).length > 0 &&
      hasStagesModule &&
      pageType !== PAGE_TYPES.INACTIVE
    ) {
      actions.push(SEND_REMINDER);
    }
    if (
      acrossedStageTypes.indexOf(STAGE_TYPE.OFFER) !== -1 &&
      acrossedStageStatuses.indexOf('sent') !== -1 &&
      hasStagesModule
    ) {
      actions.push(SEND_REMINDER);
    }
  }

  // Scheduling stage related actions
  const showScheduleRelatedActions = isBulkSelect
    ? _.difference(acrossedStageTypes, [STAGE_TYPE.SCHEDULING]).length === 0
    : acrossedStageTypes.indexOf(STAGE_TYPE.SCHEDULING) !== -1;
  if (showScheduleRelatedActions) {
    // 'Mark complete'
    if (!_.isEqual(acrossedStageStatuses, ['Completed']) && pageType !== PAGE_TYPES.INACTIVE) {
      actions.push(MARK_SCHEDULE_COMPLETE);
    }
    if (!_.isEqual(acrossedStageStatuses, ['No Show']) && pageType !== PAGE_TYPES.INACTIVE) {
      // 'Mark as no-show'
      actions.push(MARK_SCHEDULE_NO_SHOW);
    }
    // 'Request reschedule'
    if (
      _.intersection(acrossedStageStatuses, ['Confirmed', 'Cancelled', 'Completed', 'No Show']).length > 0 &&
      pageType !== PAGE_TYPES.INACTIVE
    ) {
      actions.push(REQUEST_RESCHEDULE);
    }

    // 'Cancel meeting'
    if (
      _.intersection(acrossedStageStatuses, ['Request Reschedule', 'Confirmed', 'Pending', 'Completed', 'No Show'])
        .length > 0 &&
      pageType !== PAGE_TYPES.INACTIVE
    ) {
      actions.push(CANCEL_MEETING);
    }

    // 'Confirm meeting'
    if (_.intersection(acrossedStageStatuses, ['Pending']).length > 0) {
      actions.push(CONFIRM_MEETING);
    }

    // 'Assign time'
    if (
      !isBulkSelect &&
      _.intersection(acrossedStageStatuses, ['Not Submitted', 'Request Reschedule']).length > 0 &&
      pageType !== PAGE_TYPES.INACTIVE
    ) {
      actions.push(ASSIGN_TIME);
    }
  }

  // Signing stage related actions
  const showOnlineSigningRelatedActions = isBulkSelect
    ? _.difference(acrossedStageTypes, [STAGE_TYPE.OFFER]).length === 0
    : acrossedStageTypes.indexOf(STAGE_TYPE.OFFER) !== -1;

  if (showOnlineSigningRelatedActions) {
    // 'Send signing request'
    if (
      pageType === PAGE_TYPES.POSITION_APPLICANTS ||
      pageType === PAGE_TYPES.PROFILE ||
      (pageType === PAGE_TYPES.ALL_APPLICANTS && !isBulkSelect)
    ) {
      actions.push(SEND_SIGNING_REQUEST);
    }
  }

  // Background check stage related actions
  const showBackgroundCheckRelatedActions = isBulkSelect
    ? _.difference(acrossedStageTypes, [STAGE_TYPE.BACKGROUND_CHECK]).length === 0
    : acrossedStageTypes.indexOf(STAGE_TYPE.BACKGROUND_CHECK) !== -1 &&
      hasFunctionModule(appState, constants.FUNCTION_MODULES.BACKGROUND_CHECKING);

  if (showBackgroundCheckRelatedActions) {
    // 'Send signing request'
    if (
      pageType === PAGE_TYPES.POSITION_APPLICANTS ||
      pageType === PAGE_TYPES.PROFILE ||
      (pageType === PAGE_TYPES.ALL_APPLICANTS && !isBulkSelect)
    ) {
      actions.push(ORDER_BACKGROUND_CHECK);
    }
  }

  // reorder the actions, check this doc: https://docs.google.com/document/d/1yXDtWgjOPX0NHO53RSHfhWxyMIklPyqAc06C5M6q25c/edit#heading=h.jj6igs3922ck
  const order = [
    MARK_HIRED,
    MARK_NOT_HIRED,
    REJECT,
    UN_REJECT,
    UNREJECT_APPLICANT,
    MARK_AS_INACTIVE,
    MOVE_TO_STAGE,
    MOVE_TO_POSITION,
    SEND_REMINDER,
    CONFIRM_MEETING,
    REQUEST_RESCHEDULE,
    CANCEL_MEETING,
    ASSIGN_TIME,
    MARK_SCHEDULE_COMPLETE,
    MARK_SCHEDULE_NO_SHOW,
    ORDER_BACKGROUND_CHECK,
    SEND_SIGNING_REQUEST,
    ADD_NOTE,
    SHARE_APPLICANT,
    SEND_TO_PAYLOCITY,
    SEND_TO_UKG,
    PRINT_APPLICANT,
    START_ONBOARDING,
    SEND_EMAIL_INACTIVE,
    SEND_SMS_INACTIVE,
  ];

  actions.sort((a, b) => order.indexOf(a) - order.indexOf(b));

  return _.uniq(actions);
};

export const hasRatingFunctionModule = (app) => hasFunctionModule(app, constants.FUNCTION_MODULES.RATING);

export const getYoutubeID = (url) => {
  url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  return url[2] !== undefined ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
};
export const addMinutes = (date, minutes) => {
  return new Date(date.getTime() + minutes * 60000);
};

// startTime, endTime is the string representation of time coming from Backend,
// eg: "start_time": "2019-10-26T02:00:00.000Z"
export const formatTimeSpan = (startTime, endTime) => {
  if (!startTime || !endTime) return '';
  const startString = dayjs(startTime).format('MMM D, h:mm A');
  const endString = dayjs(endTime).format('h:mm A');
  return `${startString} - ${endString}`;
};
// some time phone number can contain the region number part,
// this function is to remove that part out of the phone number.
// eg: {
//    phone: +17406170572
//    regionNumber: 1
// }

export const cleanupPhoneNumber = (phoneNumber = '', regionNumber = '') => {
  const prefix = `+${regionNumber}`;
  if (phoneNumber.indexOf(prefix) !== -1) {
    return phoneNumber.replace(prefix, '');
  }
  return phoneNumber;
};

const { ATTACHEMENT_CATEGORY } = constants;
const attachmentsURLsMap = {
  [ATTACHEMENT_CATEGORY.application_attachment]: '/v1/application_attachments/:id',
  [ATTACHEMENT_CATEGORY.application_background_check]: '/v1/application_background_checks/:id/report_pdf',
  [ATTACHEMENT_CATEGORY.application_offer_unsigned]: '/v1/application_offers/:id/unsigned_offer',
  [ATTACHEMENT_CATEGORY.application_offer_signed]: '/v1/application_offers/:id/signed_offer',
  [ATTACHEMENT_CATEGORY.application_offer_signed_zip]: '/v1/application_offers/:id/signed_offer_zip',
  [ATTACHEMENT_CATEGORY.form_answer_attachment]: '/v1/form_answer_attachments/:id',
  [ATTACHEMENT_CATEGORY.message_attachment]: '/v1/message_attachments/:id',
  [ATTACHEMENT_CATEGORY.employee_file_attachment]: '/v1/employee_attachments/:id',
};

export const getSecureAttachmentLink = (
  id: number | undefined,
  attachmentCategory: (typeof ATTACHEMENT_CATEGORY)[keyof typeof ATTACHEMENT_CATEGORY],
  format = ''
) => {
  if (!id || !attachmentCategory) return '';
  if (attachmentCategory === constants.ATTACHEMENT_CATEGORY.employee_file_attachment) {
    return APIHOST + attachmentsURLsMap[constants.ATTACHEMENT_CATEGORY.employee_file_attachment].replace(':id', id);
  }
  return APIHOST + attachmentsURLsMap[attachmentCategory].replace(':id', id) + (!format ? '' : '?type=' + format);
};

export const getSecureEmployeeAttachmentLink = (id) => {
  if (!id) return '';
  return APIHOST + attachmentsURLsMap[constants.ATTACHEMENT_CATEGORY.employee_file_attachment].replace(':id', id);
};

// This method is turn the old plain multiple line string created by a textare component
// into <br> tag seperated string. so It can rendered correctly in react-rte
export const transformPlainMultilineStringToMarkup = (str = '') => {
  if (!str) {
    return '';
  }

  // Temp solution for https://workstreamhq.atlassian.net/browse/WS-24118,
  // to replace unicode whitespace character `<0xa0>` with a normal whitespace.
  let transformedStr = str.replace(/\u00a0/g, ' ');

  if (
    transformedStr.indexOf('<br>') !== -1 ||
    transformedStr.indexOf('<p>') !== -1 ||
    transformedStr.indexOf('<ul>') !== -1 ||
    transformedStr.indexOf('<h3>') !== -1
  ) {
    return transformedStr;
  }

  return `${transformedStr.replace(/(?:\r\n|\r|\n)/g, '<br>')}`;
};

export const launchHSTemplateEditor = (editURL, successCallback, documentContainer = '', newSdk = false) => {
  // Using the new hellosign sdk lib to solve the create hellosign template problem
  const clientId = HELLOSIGN_CLIENT_ID;

  const helloSignClient = new HelloSign({ clientId });
  let config = {
    allowCancel: true,
    skipDomainVerification: SKIP_DOMAIN_VERIFICATION_HELLOSIGN,
  };
  if (documentContainer) {
    config.container = document.getElementById(documentContainer);
  }
  helloSignClient.once(HelloSign.events.CREATE_TEMPLATE, (eventData) => {
    // data example
    // {
    //   success: true
    //   templateId: "dc2405724f4766694f20b66b72061cc68cd36c8f"
    //   templateInfo: {title: 'Document Subject', message: 'Document Message', signerRoles: Array(1), ccRoles: Array(0)}
    //   templateStatusId: "dc4c3fce61e56bce3790e530ef129582b1477837"
    // }
    successCallback({ template_id: eventData.templateId });
  });
  helloSignClient.open(editURL, config);
  return helloSignClient;
};

export const shouldShowLocationLimitWall = (app) => {
  return app.company && app.company.plan_type === 'fixed_locations' && app.company.available_locations_count <= 0;
};

export const shareToMessenger = (link) => {
  const shareLink = `https://www.facebook.com/dialog/send${objectToGetParams({
    link: link,
    app_id: 1052943744786983,
    redirect_uri: link,
  })}`;
  windowOpen(shareLink, {});
};

export const shareToFacebook = (link) => {
  const shareLink = `https://www.facebook.com/sharer/sharer.php${objectToGetParams({
    u: link,
  })}`;
  windowOpen(shareLink, {});
};

export const shareToTwitter = (link, text) => {
  const linkWithReferer = `${link}?referer_source=Twitter`;
  const url = `https://twitter.com/share${objectToGetParams({ url: linkWithReferer, text })}`;
  windowOpen(url, {});
};

export const shareToGoogleplus = (link) => {
  const url = `https://plus.google.com/share${objectToGetParams({ url: link })}`;
  windowOpen(url, { windowWidth: 550, windowHeight: 400 });
};

export const shareToLinkedIn = (link) => {
  const linkWithReferer = `${link}?referer_source=Linkedin`;
  const url = `https://linkedin.com/shareArticle${objectToGetParams({ url: linkWithReferer })}`;
  windowOpen(url, {});
};

export const shareToWhatsapp = (link, text) => {
  const linkWithReferer = `${link}?referer_source=Whatsapp`;
  const url = `https://api.whatsapp.com/send${objectToGetParams({
    text: `${text} ${linkWithReferer}`,
  })}`;
  windowOpen(url, {});
};

export const slugify = (string, nonCharReplacement = '') => {
  if (!_.isString(string)) {
    return '';
  }

  return string
    .toLowerCase()
    .normalize('NFD')
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w\-]+/g, nonCharReplacement)
    .replace(/\-\-+/g, '-');
};

const windowOpen = (url, { name = '', height = 400, width = 550 }) => {
  /* eslint-disable no-mixed-operators */
  const left = window.outerWidth / 2 + (window.screenX || window.screenLeft || 0) - width / 2;
  const top = window.outerHeight / 2 + (window.screenY || window.screenTop || 0) - height / 2;
  /* eslint-enable no-mixed-operators */

  const config = {
    height,
    width,
    left,
    top,
    location: 'no',
    toolbar: 'no',
    status: 'no',
    directories: 'no',
    menubar: 'no',
    scrollbars: 'yes',
    resizable: 'no',
    centerscreen: 'yes',
    chrome: 'yes',
  };

  window.open(
    url,
    name,
    Object.keys(config)
      .map((key) => `${key}=${config[key]}`)
      .join(', ')
  );
};

/* eslint-disable prefer-template */
function objectToGetParams(object) {
  return (
    '?' +
    Object.keys(object)
      .filter((key) => !!object[key])
      .map((key) => `${key}=${encodeURIComponent(object[key])}`)
      .join('&')
  );
}
/* eslint-enable prefer-template */

export const formatSSN = (ssn) => {
  if (!ssn) return ssn;

  return ssn
    .replace(/[^\d-*]/g, '')
    .replace(/^([\d|\*]{3})/, '$1-')
    .replace(/-([\d|\*]{2})/, '-$1-')
    .replace(/([\d|\*])-([\d|\*]{4}).*/, '$1-$2');
};

// This is specifically to fix displaying 'Withold from gross taxable wages at the selected percentage' value as per
// https://workstreamhq.atlassian.net/browse/WS-37201
export const formatPercentWithheld = (input: string) => {
  const [numericPart, textPart] = input.split('_');

  if (!numericPart || !textPart) return input;

  const formattedNumber = (parseInt(numericPart, 10) / 10).toFixed(1);

  return `${formattedNumber} ${textPart}`;
};

// Use if we need to dynamically add dashes to SSN input
export const formatDynamicSSN = (ssn) => {
  return (
    ssn
      // remove all non-dash and non-numerals
      .replace(/[^\d-*]/g, '')
      // add the first dash if number from the second group appear
      .replace(/^([\d|\*]{3})-?([\d|\*]{1,2})/, '$1-$2')
      // add the second dash if numbers from the third group appear
      .replace(/^([\d|\*]{3})-?([\d|\*]{2})-?([\d|\*]{1,4})/, '$1-$2-$3')
      // remove misplaced dashes
      .split('')
      .filter((val, idx) => {
        return val !== '-' || idx === 3 || idx === 6;
      })
      .join('')
      // enforce max length
      .substring(0, 11)
  );
};

export const displayDataIntegrityError = ({ content }) => {
  // TODO: We need a better way to report the data integrity issue
  // https://workstreamhq.atlassian.net/browse/WS-6561
  AntdModal.warning({
    title: 'Essential data is missing',
    content,
    okText: 'OK',
  });
};

export const getOnboardingActionAssigneeNames = (assignees = [], availableRoles = [], availableAssignees = []) => {
  return assignees.reduce((acc, assignee) => {
    const foundRole = availableRoles.find((item) => item.id === assignee.company_role?.id);
    const foundCompanyStaff = availableAssignees.find((item) => item.id === assignee.company_staff?.id);
    if (foundRole) return [...acc, foundRole.name];
    if (foundCompanyStaff) return [...acc, foundCompanyStaff.name];
    return acc;
  }, []);
};

/**
 * Return a time range text in this style:
 * 1. Start and end are in the same noon: 9:00-10:30 AM
 * 2. Start and end are in different noon: 9:00 AM-1:00 PM
 * @param {string} startTimeString
 * @param {string} endTimeString
 * @param {string} timeZone
 */
export const getTimeRangeText = (startTimeString, endTimeString, timeZone) => {
  const startTime = dayjs(startTimeString).tz(timeZone).locale('en');
  const endTime = dayjs(endTimeString).tz(timeZone).locale('en');

  const isInSameNoon = startTime.format('A') === endTime.format('A');

  if (!isInSameNoon) {
    return `${startTime.format('h:mm A')}-${endTime.format('h:mm A')}`;
  }

  return `${startTime.format('h:mm')}-${endTime.format('h:mm A')}`;
};

export const pluralize = (word, count) => {
  if (count === 0 || count > 1) {
    return Pluralize(word);
  }

  return word;
};

//returns true if state(string) is colorado or co
export const isColorado = (state) => {
  if (!state) return false;
  if (state.toLowerCase() === 'co' || state.toLowerCase() === 'colorado') {
    return true;
  } else return false;
};

export const getSelectedGroupsOfPermittedBrands = (app) => {
  if (!app) return {};

  const { permittedBrandsWithLocations: oldPermittedBrands = [] } = app;
  const newPayload = {};
  oldPermittedBrands.forEach((brand) => {
    const {
      nickname,
      groupLocations: { selectedGroup = '' },
    } = brand;
    newPayload[nickname] = selectedGroup;
  });
  return newPayload;
};

export const calcValueCount = (value: string) => {
  if (!value) return 0;
  const regex = /\{\{[\w_]+\}\}/g;
  return value.replace(regex, '').length;
};

export const openNewTab = (url: string, direct = false) => {
  const win = window.open(direct ? url : `/#${url}`, '_blank');
  win?.focus();
};

interface openNotificationProps {
  type: IconType;
  message: ReactNode;
  className?: string;
  description?: ReactNode;
}

export const openNotification = (props: openNotificationProps) => {
  const { message, description, className, type } = props;

  ant4Notification[type]({
    message,
    description,
    className: `design-system-notification  ${className}`,
    style: {
      padding: '20px',
      borderRadius: '8px',
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '14px',
      lineHeight: '20px',
      width: '400px',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.08), 0px 8px 16px rgba(0, 0, 0, 0.12)',
    },
    duration: 2.5,
  });
};

type careerPageLinkType = 'company' | 'brand' | 'location' | 'position';

export const composeCareerPageLink = (type: careerPageLinkType, records = {}, host = copyLinkHOST) => {
  const { company = {}, brand = {}, location = {}, position = {} } = records;

  switch (type) {
    case 'company':
      return `${host}/${company.digest_key}`;
    case 'brand':
      return `${host}/${company.digest_key}/${brand.friendly_id}`;
    case 'location':
      return `${host}/${company.digest_key}/${brand.friendly_id}/${slugify(location.city, '-')}-${location.id}`.replace(
        '/-',
        '/'
      );
    case 'position':
      return `${host}/${company.digest_key}/${brand.friendly_id}/${slugify(location.city, '-')}-${
        location.id
      }/${slugify(position.title, '-')}-${position.digest_key}`.replace('/-', '/');
  }
};

export const salaryNumberFormat = (salaryNumber: number | number[]) => {
  const formatFn = (num: number) => {
    return num >= 1000 ? num?.toLocaleString() : num?.toFixed(2);
  };

  if (Array.isArray(salaryNumber)) {
    return salaryNumber.map(formatFn);
  } else {
    return formatFn(salaryNumber);
  }
};

export const extractLocations = (locationMappings) => {
  const results = [];
  locationMappings?.forEach((country) => {
    country.sub_items?.forEach((state) => {
      state.sub_items?.forEach((city) => {
        city.sub_items?.forEach((location) => {
          results.push({
            ...location,
            city_code: city.value,
            state_code: state.value,
            contry_code: country.value,
          });
        });
      });
    });
  });
  return results;
};

export const isDefaultMeetingDetails = (details: string) => {
  const phoneCallReg =
    // eslint-disable-next-line max-len
    /^This will be a \d+ minute phone interview\. We'll give you a call at your scheduled time, but you can send a text any time if you have .*questions\. Talk to you soon!$/;
  const onsiteInterviewReg =
    // eslint-disable-next-line max-len
    /^This will be a \d+ minute onsite interview\. Please proceed to the store location at your scheduled time\. You can send a text at any time if you have any questions\. See you soon!$/g;
  const videoConferenceReg =
    // eslint-disable-next-line max-len
    /^This will be a \d+ minute video conference interview\. A conference link will be sent to you for the scheduled time\. You can send a text any time if you have any questions\. Talk to you soon!$/g;

  return [phoneCallReg, onsiteInterviewReg, videoConferenceReg].some((re) => details?.match(re)?.length > 0);
};

export const getDefaultMeetingDetails = (meetingType: string, meetingLengthValue: string) => {
  let detailsContent = '';
  switch (meetingType) {
    case 'Phone call':
      detailsContent = `This will be a ${meetingLengthValue} minute phone interview. We'll give you a call at your scheduled time, but you can send a text any time if you have any questions. Talk to you soon!`;
      break;
    case 'Onsite interview':
      detailsContent = `This will be a ${meetingLengthValue} minute onsite interview. Please proceed to the store location at your scheduled time. You can send a text at any time if you have any questions. See you soon!`;
      break;
    case 'Video conference':
      detailsContent = `This will be a ${meetingLengthValue} minute video conference interview. A conference link will be sent to you for the scheduled time. You can send a text any time if you have any questions. Talk to you soon!`;
      break;
    case 'Other':
      detailsContent = '';
      break;
    default:
  }
  return detailsContent;
};
