import React, { useState } from 'react';
import { FlexBox, Typography } from '@helloworld1812/ws-components';
import { useTheme } from 'styled-components';
import { useUsersMeCached } from '@helloworld1812/hr-api';

import { HiringPaywall, HiringPaywallModal, useIsLiteTier } from '@/components/tier-lite/hiring-paywall';

import { S } from './hiring-paywall-wrapper.styles';
import { HiringPaywallWrapperProps } from './hiring-paywall-wrapper.types';

export const HiringPaywallWrapper = (props: HiringPaywallWrapperProps) => {
  const {
    children,
    mode = 'paywall',
    block = false,
    disabledStyle = 'none',
    hasNoFunctionModule: propHasNoFunctionModule,
  } = props;
  const [isModalVisible, setModalVisible] = useState(false);
  const [isPopoverVisible, setPopoverVisible] = useState(false);
  const theme = useTheme();
  const isLiteTier = useIsLiteTier();
  const { user } = useUsersMeCached();
  const hasNoFunctionModule =
    typeof propHasNoFunctionModule === 'boolean'
      ? propHasNoFunctionModule
      : !user?.company?.function_modules?.includes(propHasNoFunctionModule);

  const isPaywallVisible = isLiteTier && hasNoFunctionModule;

  if (!isPaywallVisible) {
    return children;
  }

  if (mode === 'hidden') {
    return null;
  }

  if (mode === 'hover') {
    return (
      <>
        <S.Popover
          visible={isPopoverVisible && !isModalVisible}
          onVisibleChange={setPopoverVisible}
          content={
            <FlexBox direction="column" gap={8}>
              <Typography fontWeight="bold" color="textPrimary">
                Feature not included in Lite Tier
              </Typography>
              <Typography color="textSecondary">Want to see it in action? Get in touch with us! </Typography>
              <Typography variant="link" onClick={() => setModalVisible(true)}>
                Contact us for a demo
              </Typography>
            </FlexBox>
          }
          trigger="hover"
          placement="bottom"
          zIndex={theme.zIndex.snackbar + 1}
        >
          <S.HoverCardWrapper className="hiring-paywall-wrapper" block={block} disabledStyle={disabledStyle}>
            {children}
            <S.GrayOutWrapper />
          </S.HoverCardWrapper>
        </S.Popover>
        {isModalVisible && (
          <HiringPaywallModal closeIcon visible={isModalVisible} onCancel={() => setModalVisible(false)} />
        )}
      </>
    );
  }
  return <HiringPaywall />;
};
